import React from 'react';
import moment from 'moment';
import axios from "axios";
import { Button, Input, message, Space, Table, Tag } from "antd";
import Highlighter from 'react-highlight-words';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { EditOutlined, SearchOutlined, ShareAltOutlined } from '@ant-design/icons';
import "./TaskList.less";

import TaskCard from './components/TaskCard';
import EditTaskModal from './components/TaskCard/components/Info/components/EditTaskModal';
import { getBlockExplorerLink } from "../../utils/utils";
import { DEX_EXCHANGES } from '../../constants/exchanges';

const { Search } = Input;
const dateFormat = "DD.MM.YYYY HH:mm:ss";

class TaskList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchedColumn: null,
      showFilter: props.hasOwnProperty("showFilter") ? props.showFilter : true,
      showEditButton: props.hasOwnProperty("showEditButton") ? props.showEditButton : false,
      showTaskCardButton: props.hasOwnProperty("showTaskCardButton") ? props.showTaskCardButton : true,
      showSearchInput: props.hasOwnProperty("showSearchInput") ? props.showSearchInput : false,
      hideColumns: props.hasOwnProperty("hideColumns") ? props.hideColumns : [],
      editTask: null,
      searching: false,
      filteredIds: false,
      windowWidth: undefined,
      loading: false,
    }

    this.edit = this.edit.bind(this);
    this.editClose = this.editClose.bind(this);
    this.editCallback = this.editCallback.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  componentDidMount() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  handleResize = () => this.setState({
    windowWidth: window.innerWidth
  });

  componentDidUpdate(prevProps) {
    if (this.props.loading !== prevProps.loading) {
      this.setState({ loading: this.props.loading });
    }
  }

  getColumnSearchProps(dataIndex) {
    if (!this.state.showFilter) {
      return {}
    }

    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={node => {
              this.searchInput = node;
            }}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Reset
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                confirm({ closeDropdown: false });
                this.setState({
                  searchText: selectedKeys[0],
                  searchedColumn: dataIndex,
                });
              }}
            >
              Filter
            </Button>
          </Space>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '',
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select(), 100);
        }
      },
      render: text =>
        this.state.searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    }
  };

  handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset(clearFilters) {
    clearFilters();
    this.setState({ searchText: '' });
  };

  edit(e, id) {
    e.preventDefault();
    this.setState({
      editTask: id
    })
  }

  editClose() {
    this.setState({
      editTask: null
    })
  }

  editCallback() {
    this.props.reload();
  }

  async onSearch(value) {
    if (this.state.searching) {
      return;
    }

    if (!value || value === "") {
      this.setState({ filteredIds: false });
      return;
    }

    this.setState({ searching: true });

    try {
      let response = await axios(`/api/tasks/search?projectId=${this.props.projectId}&search=${value}`);
      if (response.status !== 200) {
        throw new Error(response.message || "Internal server error");
      }

      this.setState({
        filteredIds: response.data.ids
      })
    } catch (e) {
      message.error(e.message, 8);
    }

    this.setState({ searching: false });
  }

  render() {
    let { showFilter, hideColumns, showTaskCardButton, showEditButton, showSearchInput, editTask, searching, filteredIds, windowWidth, loading } = this.state;
    let { operations } = this.props;
    const exchanges = [];
    const statuses = [];
    const triggerTypes = [];
    const miners = [];
    const errorTypes = [];

    let tasks = filteredIds !== false ? operations.filter(op => filteredIds.includes(op.key)) : operations;
    if (showFilter) {
      for (let i in tasks) {
        let task = operations[i];
        if (statuses.indexOf(task.status) < 0) {
          statuses.push(task.status);
        }

        if (errorTypes.indexOf(task.errorType) < 0) {
          errorTypes.push(task.errorType);
        }

        if (triggerTypes.indexOf(task.triggerType || "no data") < 0) {
          triggerTypes.push(task.triggerType || "no data");
        }

        if (!!task.miner && miners.indexOf(task.miner) < 0) {
          miners.push(task.miner);
        }

        if (exchanges.indexOf(task.exchangeFrom) < 0) {
          exchanges.push(task.exchangeFrom);
        }

        if (exchanges.indexOf(task.exchangeTo) < 0) {
          exchanges.push(task.exchangeTo);
        }
      }
    }

    const renderPoolType = (value, recordTypes) => {
      return <>
        {
          !!recordTypes && DEX_EXCHANGES.includes(value) &&
          <span className='mo-types'>
            {
              recordTypes.map((type, i) => (
                <span key={i}>
                  {
                    type[0] + type.at(-1)
                  }
                  {
                    i < recordTypes.length - 1 && '+'
                  }
                </span>)
              )
            }
          </span>
        }
      </>
    }

    const columns = [
      {
        title: 'ID',
        dataIndex: 'taskId',
        key: 'taskId',
        width: "110px",
        fixed: windowWidth > 800 ? 'left' : false,
        ...this.getColumnSearchProps('taskId'),
        render: id => (
          <CopyToClipboard text={id} onCopy={() => {
            message.success('Copied!');
          }}>
            <a href="#" onClick={(e) => e.preventDefault()}>{id.substr(0, 6)}...{id.substr(-6)}</a>
          </CopyToClipboard>
        )
      },
      {
        title: 'Token',
        dataIndex: 'tokenSymbol',
        key: 'tokenSymbol',
        width: "70px",
        fixed: windowWidth > 800 ? 'left' : false,
        ...this.getColumnSearchProps('tokenSymbol'),
      },
      {
        title: '',
        dataIndex: 'isFromMultihop',
        key: 'isFromMultihop',
        width: "20px",
        filters: !!showFilter ? [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ] : undefined,
        onFilter: (value, record) => value === !!record.isFromMultihop,
        render: (isFromMultihop) => (
          <>{isFromMultihop ? <ShareAltOutlined /> : ""}</>
        ),
      },
      {
        title: 'From',
        dataIndex: 'exchangeFrom',
        key: 'exchangeFrom',
        width: "70px",
        filters: !!showFilter
          ? exchanges.map(ex => {
            return {
              text: ex,
              value: ex,
              children: ex === 'uniswap'
                ? [
                  {
                    text: 'All',
                    value: 'all',
                  },
                  {
                    text: 'Only v2',
                    value: 'v2',
                  },
                  {
                    text: 'Only v3',
                    value: 'v3',
                  },
                  {
                    text: 'v2 + v3',
                    value: 'v2v3',
                  },
                ]
                : undefined
            }
          })
          : undefined,
        onFilter: (value, record) => {
          return value === 'v2'
            ? record.exchangeFrom === 'uniswap' && record.typesFrom.length === 1 && record.typesFrom[0] === 'uniswapV2'
            : value === 'v3'
              ? record.exchangeFrom === 'uniswap' && record.typesFrom.length === 1 && record.typesFrom[0] === 'uniswapV3'
              : value === 'v2v3'
                ? record.exchangeFrom === 'uniswap'
                && record.typesFrom.length === 2
                && record.typesFrom.some(type => type === 'uniswapV2')
                && record.typesFrom.some(type => type === 'uniswapV3')
                : value === 'all'
                  ? record.exchangeFrom === 'uniswap'
                  : record.exchangeFrom.indexOf(value) === 0;
        },
        render: (value, record) => {
          return <>
            {value}
            {
              renderPoolType(value, record.typesFrom)
            }
          </>
        },
      },
      {
        title: 'To',
        dataIndex: 'exchangeTo',
        key: 'exchangeTo',
        width: "70px",
        filters: !!showFilter ? exchanges.map(ex => { return { text: ex, value: ex } }) : undefined,
        onFilter: (value, record) => record.exchangeTo.indexOf(value) === 0,
        render: (value, record) => {
          return <>
            {value}
            {
              renderPoolType(value, record.typesTo)
            }
          </>
        },
      },
      {
        title: '',
        dataIndex: 'isToMultihop',
        key: 'isToMultihop',
        width: "20px",
        filters: !!showFilter ? [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ] : undefined,
        onFilter: (value, record) => value === !!record.isToMultihop,
        render: (isToMultihop) => (
          <>{isToMultihop ? <ShareAltOutlined /> : ""}</>
        ),
      },
      {
        title: 'Trigger type',
        dataIndex: 'triggerType',
        key: 'triggerType',
        width: "150px",
        filters: !!showFilter ? triggerTypes.map(tt => { return { text: tt, value: tt } }) : undefined,
        onFilter: (value, record) => record.triggerType.indexOf(value) === 0,
      },
      {
        title: 'Found in block',
        dataIndex: 'foundInBlock',
        key: 'foundInBlock',
        width: "90px",
        filters: !!showFilter ? [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ] : undefined,
        onFilter: (value, record) => value === !!record.foundInBlock,
      },
      {
        title: 'Mining block',
        dataIndex: 'miningBlock',
        key: 'miningBlock',
        width: "90px",
        filters: !!showFilter ? [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ] : undefined,
        onFilter: (value, record) => value === !!record.miningBlock,
        render: (miningBlock, record) => {
          return <><a href={`${getBlockExplorerLink(record.exchangeFrom)}/block/` + miningBlock} target="_blank">{miningBlock}</a> {!!record.foundInBlock && !!miningBlock ? <i>({miningBlock - record.foundInBlock})</i> : ""}</>
        }
      },
      {
        title: 'Miner',
        dataIndex: 'miner',
        key: 'miner',
        width: "70px",
        filters: !!showFilter ? miners.map(tt => { return { text: tt, value: tt } }) : undefined,
        onFilter: (value, record) => record.miner === value,
        render: (miner, record) => {
          return miner.length < 40 ? <>{miner}</> : <a href={`${getBlockExplorerLink(record.exchangeFrom)}/address/` + miner} target="_blank">{miner.substr(0, 2) + '...' + miner.substr(-3)}</a>
        }
      },
      {
        title: 'Vol.',
        dataIndex: 'volume',
        key: 'volume',
        align: "right",
        width: "40px",
        sorter: !!showFilter ? (a, b) => a.volume - b.volume : undefined,
      },
      {
        title: 'Est. result',
        dataIndex: 'estResult',
        key: 'estResult',
        align: "right",
        width: "90px",
        render: (value) => <>{+value.toFixed(6)}</>,
        sorter: !!showFilter ? (a, b) => a.estResult - b.estResult : undefined,
      },
      {
        title: 'Est. profit',
        dataIndex: 'estProfit',
        key: 'estProfit',
        align: "right",
        width: "90px",
        render: (value) => <>{+value.toFixed(4)}</>,
        sorter: !!showFilter ? (a, b) => a.estProfit - b.estProfit : undefined,
      },
      {
        title: 'Real profit',
        dataIndex: 'realProfit',
        key: 'realProfit',
        align: "right",
        width: "100px",
        sorter: !!showFilter ? (a, b) => a.realProfit - b.realProfit : undefined,
        render: profit => {
          let color = 'inherit';
          if (profit && profit < 0) {
            color = 'red'
          } else if (profit && profit > 0) {
            color = 'green'
          }
          return <span style={{ color: color }}>{+profit.toFixed(4)}</span>
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        align: "center",
        width: "140px",
        filters: !!showFilter ? statuses.map(status => { return { text: status, value: status } }) : undefined,
        onFilter: (value, record) => record.status.indexOf(value) === 0,
        render: (status, record) => (
          <>
            <Tag color={
              status === 'success'
                ? "green"
                : status === "error"
                  ? "red"
                  : status === "terminated"
                    ? "orange"
                    : "blue"
            }>
              {status}
            </Tag>
            {
              record.hasManualProcessing &&
              <Tag color="default">edited</Tag>
            }
          </>
        ),
      },
      {
        title: 'Error type',
        dataIndex: 'errorType',
        key: 'errorType',
        filterMode: 'tree',
        filters: !!showFilter ? errorTypes.sort((a, b) => a > b ? 1 : 0).map(type => { return { text: type || '-', value: type } }) : undefined,
        onFilter: (value, record) => value === record.errorType,
      },
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: "130px",
        fixed: windowWidth > 800 ? 'right' : false,
        render: (date) => <>{moment(date).format(dateFormat)}</>,
        sorter: !!showFilter ? (a, b) => (new Date(a.date)).getTime() - (new Date(b.date)).getTime() : undefined,
      },
    ];

    if (showEditButton) {
      columns.push({
        title: '',
        dataIndex: 'edit',
        key: "edit",
        align: "right",
        width: "25px",
        fixed: windowWidth > 800 ? 'right' : false,
        render: (data, row) => (
          <Button type="danger" size={"small"} onClick={(e) => this.edit(e, row.key)}>
            <EditOutlined />
          </Button>
        )
      })
    }

    return (
      <>
        {
          showSearchInput &&
          <Search
            key={"search-input"}
            placeholder="Search by tx hash"
            onSearch={this.onSearch}
            enterButton
            disabled={loading}
            loading={searching}
            style={
              {
                width: "496px",
                marginTop: "-20px",
                marginBottom: "10px",
                float: "right"
              }
            }
          />
        }
        <Table
          key={"tasks-table"}
          className={"tasks-table"}
          columns={columns.filter(col => hideColumns.indexOf(col.dataIndex) < 0)}
          dataSource={tasks}
          size="small"
          pagination={{ defaultPageSize: 25 }}
          loading={loading}
          rowClassName={(row) => 'task-' + row.id}
          scroll={{ x: "max-content" }}
          expandable={
            !!showTaskCardButton
              ? {
                expandedRowRender: record => <TaskCard id={record.key} user={this.props.user} currency={this.props.currency} projectId={this.props.projectId} />,
                rowExpandable: record => !!record,
              }
              : undefined
          }
        />

        {
          !!showEditButton && !!editTask &&
          <EditTaskModal
            key={"edit-tasks-modal"}
            taskId={editTask}
            visible={!!editTask}
            onClose={this.editClose}
            user={this.props.user}
            callback={this.editCallback}
          />
        }
      </>

    )
  }
}

export default TaskList;
