import React from 'react';
import moment from 'moment';
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { CSVLink } from "react-csv";
import { DatePicker, Spin, PageHeader, Typography, Tooltip, Alert, Row, Col, Table, Form, Switch } from "antd";
import { CloudDownloadOutlined } from '@ant-design/icons';
import { getCsvData } from '../../../utils/csvReport';
import "./OneinchReport.less";

import TaskList from '../../TaskList';
import { taskHandler, getDateFormat } from '../../TaskList/taskListHelpers';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const step = 1000;
let loadingID = null;

class OneinchReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            operations: [],
            csvData: [],
            loading: false,
            error: false,
            datePickerValue: this.getDefaultDateRange(),
            showDropped: false,
            profit: 0,
            revenue: 0,
            losses: 0,
            estProfit: 0
        }

        this.loadOperations = this.loadOperations.bind(this);
        this.onChangeDatePicker = this.onChangeDatePicker.bind(this);
    }

    handleToggle = prop => enable => {
        this.setState({ [prop]: enable });
    };

    getDefaultDateRange() {
        let result = [
            moment().subtract(30, 'days'),
            moment()
        ];

        return result;
    }

    componentDidMount() {
        this.loadOperations();
    }

    async loadOperations() {
        const { datePickerValue } = this.state;
        let timeFrom = datePickerValue[0].toDate().getTime();
        let timeTo = datePickerValue[1].toDate().getTime();

        loadingID = timeFrom + "-" + timeTo;
        this.setState({ revenue: 0, profit: 0, losses: 0 });

        this.setState({ operations: [], loading: true, error: false });
        for (let i = 0; i >= 0; i += step) {
            try {
                let response = await fetch(`/api/tasks?from=${timeFrom}&to=${timeTo}&limit=${step}&offset=${i}&triggerType=oneInchMemPoolPredictionUpdate`, {
                    headers: {
                        "x-access-token": this.props.user['accessToken']
                    }
                });

                if (loadingID !== timeFrom + "-" + timeTo) {
                    return false;
                }

                let operations = await response.json();
                if (response.status !== 200) {
                    this.setState({ error: operations.message });
                    break;
                } else {
                    let newOperations = taskHandler([].concat(...this.state.operations), operations);
                    await this.setState({ operations: newOperations });
                    if (operations.length < 1000) {
                        break;
                    }
                }
            } catch (e) {
                this.setState({ error: e.message });
            }
        }

        let revenue = 0, losses = 0, estProfit = 0;

        this.state.operations.forEach(task => {
            if(['inProgress'].indexOf(task.status) >= 0 ){
                return false;
            }

            if (task.realProfit > 0) {
                revenue += task.realProfit
            } else {
                losses += task.realProfit
            }
            
            if (task.realProfit !== 0) {
                estProfit += task.estProfit
            }
        })

        this.setState({
            csvData: getCsvData(this.state.operations, ['key', 'id', 'types', 'hasManualProcessing', 'triggerType', 'foundInBlock', 'ethTransactions']),
            profit: revenue + losses,
            revenue,
            losses,
            estProfit
        });

        this.setState({ loading: false });
        loadingID = null;
    }

    async onChangeDatePicker(value) {
        this.props.history.push({
            pathname: '/reports/1inch-report',
            search: `?from=${value[0].toDate().getTime()}&to=${value[1].toDate().getTime()}`
        });

        await this.setState({ datePickerValue: value });
        this.loadOperations();
    }

    async getPDF(tasksCount, tasksProfitableCount, tasksDroppedCount) {
        let { datePickerValue, revenue, profit, losses, estProfit, operations } = this.state;

        let tasks = operations.filter(task => !(task.realProfit === 0 && task.status === 'error') && ['inProgress'].indexOf(task.status) < 0);
        
        const doc = new jsPDF();
        doc.addFont("../../../../public/fonts/DM_Sans/DMSans-Regular.ttf", "DM sans", "normal");
        doc.addFont("../../../../public/fonts/DM_Sans/DMSans-Bold.ttf", "DM sans", "bold");
        doc.addFont("../../../../public/fonts/DM_Sans/DMSans-Italic.ttf", "DM sans", "italic");

        doc.addImage("../../../../public/imgs/peanut-logo.png", "PNG", 70, 15, 70, 15);

        doc.setFont("DM sans", "bold");

        doc.setFontSize(22);
        doc.text("Report", 105, 50, null, null, "center");

        doc.setFont("DM sans", "italic");
        doc.setFontSize(12);
        doc.text(`${datePickerValue[0].utc().format("DD.MM.YYYY HH:mm:ss")} - ${datePickerValue[1].utc().format("DD.MM.YYYY HH:mm:ss")} (UTC)`, 105, 60, null, null, "center");

        doc.setLineWidth(0.05);
        doc.setDrawColor(0, 0, 0);

        doc.setFontSize(14);
        doc.setFont("DM sans", "bold");
        doc.text(`Profit`, 20, 75);
        doc.text(`${+profit.toFixed(4)} ETH`, 190, 75, null, "right");

        doc.setLineDash([1]);
        doc.line(20, 76, 190, 76);

        doc.setFont("DM sans", "normal");
        doc.text(`Expected profit`, 20, 85);
        doc.text(`${+estProfit.toFixed(4)} ETH`, 190, 85, null, "right");
        doc.line(20, 86, 190, 86);

        doc.setFont("DM sans", "normal");
        doc.text(`Revenue`, 20, 95);
        doc.text(`${+revenue.toFixed(4)} ETH`, 190, 95, null, "right");
        doc.line(20, 96, 190, 96);

        doc.text(`Losses`, 20, 105);
        doc.text(`${+losses.toFixed(4)} ETH`, 190, 105, null, "right");
        doc.line(20, 106, 190, 106);

        doc.setFontSize(18);
        doc.setFont("DM sans", "bold");
        doc.text("Requests", 105, 125, null, null, "center");

        doc.setFontSize(14);
        doc.text(`Total`, 20, 135);
        doc.text(`${tasksCount}`, 190, 135, null, "right");
        doc.line(20, 136, 190, 136);

        doc.setFont("DM sans", "normal");
        doc.text(`Profitable`, 20, 145);
        doc.text(`${tasksProfitableCount}`, 190, 145, null, "right");
        doc.line(20, 146, 190, 146);

        doc.text(`Dropped:`, 20, 155);
        doc.text(`${tasksDroppedCount}`, 190, 155, null, "right");
        doc.line(20, 156, 190, 156);


        doc.setFont("DM sans", "normal");
        doc.setFontSize(12);
        doc.text(`© Peanut.trade • Powered by Remme`, 105, 280, null, "center");

        doc.addPage("a4", "l");
        doc.setFontSize(8);

        autoTable(doc, {
            styles: {
                fontSize: 8,
            },
            head: [[
                'TaskId',
                'Token',
                'From',
                'To',
                { content: 'Mining block', styles: { halign: 'right' } },
                { content: 'Volume', styles: { halign: 'right' } },
                { content: 'Est. result', styles: { halign: 'right' } },
                { content: 'Est. profit', styles: { halign: 'right' } },
                { content: 'Real profit', styles: { halign: 'right' } },
                { content: 'Tx', styles: { halign: 'center' } },
                { content: 'Status', styles: { halign: 'center' } },
                'Date (UTC)'
            ]],
            body: tasks.map(task => {
                return [
                    task.taskId,
                    task.tokenSymbol,
                    task.exchangeFrom,
                    task.exchangeTo,
                    { content: task.miningBlock || "", styles: { halign: 'right' } },
                    { content: task.volume || 0, styles: { halign: 'right' } },
                    { content: task.estResult || 0, styles: { halign: 'right' } },
                    { content: task.estProfit || 0, styles: { halign: 'right' } },
                    { content: task.realProfit || 0, styles: { halign: 'right' } },
                    { content: task.ethTransactions.length > 0 ? " " : "", styles: { halign: 'center' } },
                    { content: task.status || "", styles: { halign: 'center' } },
                    moment(task.date).utc().format("DD.MM.YYYY HH:mm:ss")
                ]
            }),
            didDrawCell: function (data) {
                if (data.column.index === 9 && data.cell.section === 'body' && data.cell.text[0] === " ") {
                    let task = tasks.find(t => t.taskId === data.row.raw[0]);
                    doc.setTextColor(66,135,245);
                    doc.textWithLink('link', data.cell.x + 2.5, data.cell.y + 4.11, {
                        url: 'https://etherscan.io/tx/' + task.ethTransactions[task.ethTransactions.length - 1].txHash,
                    });
                    doc.setTextColor(0,0,0);
                }
            }
        });

        doc.save(`report_${this.state.datePickerValue[0].utc().format("DD.MM.YYYY_HH.mm.ss")}-${datePickerValue[1].utc().format("DD.MM.YYYY_HH.mm.ss")}.pdf`);
    }

    render() {
        let { error, loading, operations, csvData, datePickerValue, revenue, profit, losses, estProfit, showDropped } = this.state;

        let tasksCount = operations.length;
        let tasksSuccessfulCount = operations.filter(task => task.status === 'success').length;
        let tasksProfitableCount = operations.filter(task => task.status === 'success' && task.realProfit > 0).length;
        let tasksFailedCount = operations.filter(task => task.status === 'error' && task.realProfit !== 0).length;
        let tasksDroppedCount = operations.filter(task => task.status === 'error' && task.realProfit === 0).length;

        return (
            <div className={"oneinch-report"}>
                <PageHeader
                    className={'main-page-content'}
                    title={<Title>1inch Report {loading && tasksCount > 1 && <Spin />}</Title>}
                    extra={[
                        <Tooltip key={"pdf"} placement="bottom" title={!!loading ? "wait" : null}>
                            <div className={"download-button"} onClick={() => this.getPDF(tasksCount, tasksProfitableCount, tasksDroppedCount)}>
                                pdf <CloudDownloadOutlined />
                            </div>
                        </Tooltip>,
                        <Tooltip key={"csv"} placement="bottom" title={!!loading ? "wait" : null}>
                            <CSVLink
                                filename={`1inch_tasks_${datePickerValue[0].format("DD.MM.YYYY_HH꞉mm꞉ss")}_${datePickerValue[1].format("DD.MM.YYYY_HH꞉mm꞉ss")}.csv`}
                                className={"download-button"}
                                data={csvData}
                                disabled={!!loading}
                                target="_blank"
                            >
                                csv <CloudDownloadOutlined />
                            </CSVLink>
                        </Tooltip>,
                        <RangePicker
                            key={"range"}
                            showTime={{ format: 'HH:mm' }}
                            format={getDateFormat()}
                            defaultValue={datePickerValue}
                            onCalendarChange={this.onChangeDatePicker}
                        />
                    ]}
                >
                    <div className="site-card-border-less-wrapper">
                        {
                            !!error &&
                            <Alert message={error} type="error" banner />
                        }
                        <Row className={"report-section"} gutter={24}>
                            <Col span={24} md={{ span: 12 }}>
                                <Table
                                    className={"report-table"}
                                    showHeader={false}
                                    dataSource={[
                                        {
                                            "key": "profit",
                                            "1": <b>Real profit</b>,
                                            "2": <b>{+profit.toFixed(4)} ETH</b>
                                        },
                                        {
                                            "key": "estProfit",
                                            "1": "Expected profit",
                                            "2": `${+estProfit.toFixed(4)} ETH`
                                        },
                                        {
                                            "key": "revenue",
                                            "1": "Revenue",
                                            "2": `${+revenue.toFixed(4)} ETH`
                                        },
                                        {
                                            "key": "losses",
                                            "1": "Losses",
                                            "2": `${+losses.toFixed(4)} ETH`
                                        },
                                    ]}
                                    columns={[
                                        {
                                            title: '',
                                            dataIndex: "1",
                                            key: 1,
                                            width: "130px"
                                        },
                                        {
                                            title: '',
                                            dataIndex: "2",
                                            key: 2,
                                            align: "right"
                                        }
                                    ]}
                                    size={"small"}
                                    pagination={false}
                                />
                            </Col>
                            <Col span={24} md={{ span: 12 }}>
                                <Table
                                    className={"report-table"}
                                    showHeader={false}
                                    dataSource={[
                                        {
                                            "key": "tasks",
                                            "1": <b>Tasks</b>,
                                            "2": <b>{tasksCount}</b>
                                        },
                                        {
                                            "key": "tasks_successful",
                                            "1": "Successful",
                                            "2": `${tasksSuccessfulCount}`,
                                            "3": `${+(tasksSuccessfulCount * 100 / tasksCount).toFixed() || 0}%`
                                        },
                                        {
                                            "key": "tasks_profitable",
                                            "1": "Profitable",
                                            "2": `${tasksProfitableCount}`,
                                            "3": `${+(tasksProfitableCount * 100 / tasksCount).toFixed() || 0}%`
                                        },
                                        {
                                            "key": "tasks_failed",
                                            "1": "Failed",
                                            "2": `${tasksFailedCount}`,
                                            "3": `${+(tasksFailedCount * 100 / tasksCount).toFixed() || 0}%`
                                        },
                                        {
                                            "key": "tasks_dropped",
                                            "1": "Dropped",
                                            "2": `${tasksDroppedCount}`,
                                            "3": `${+(tasksDroppedCount * 100 / tasksCount).toFixed() || 0}%`
                                        }
                                    ]}
                                    columns={[
                                        {
                                            title: '',
                                            dataIndex: "1",
                                            key: 1,
                                            width: "100px"
                                        },
                                        {
                                            title: '',
                                            dataIndex: "2",
                                            key: 2,
                                            align: "right"
                                        },
                                        {
                                            title: '',
                                            dataIndex: "3",
                                            key: 3,
                                            align: "right"
                                        }
                                    ]}
                                    size={"small"}
                                    pagination={false}
                                />
                            </Col>
                        </Row>
                        <Form
                            layout="inline"
                            className="show-dropped-toggle"
                            style={{ marginBottom: 16 }}
                        >
                            <Form.Item label="Dropped tasks">
                                <Switch checked={showDropped} onChange={this.handleToggle('showDropped')} />
                            </Form.Item>
                        </Form>
                        <TaskList
                            loading={loading && tasksCount === 0}
                            operations={!showDropped ? operations.filter(task => !(task.realProfit === 0 && task.status === 'error')) : operations}
                            user={this.props.user}
                            hideColumns={['triggerType', 'startBlock']}
                            reload={this.loadOperations}
                        />
                    </div>
                </PageHeader>
            </div>
        )
    }
}

export default OneinchReport;