import React from 'react';
import moment from 'moment';
import { CSVLink } from "react-csv";
import { CloudDownloadOutlined } from '@ant-design/icons';
import { getCsvData } from '../../../utils/csvReport';
import { PageHeader, Typography, Table, Select } from "antd";
import "./TaskVolumes.less";
import axios from 'axios';

const { Title } = Typography;

const ranges = [
    [0, 1, '< 1'],
    [1, 2],
    [2, 3],
    [3, 4],
    [4, 5],
    [5, 6],
    [6, 7],
    [7, 8],
    [8, 9],
    [9, 10],
    [10, 11],
    [11, 12],
    [12, 14],
    [14, 16],
    [16, 18],
    [18, 20],
    [20, 22],
    [22, 25],
    [25, 9999999999, '> 25'],
]

const columns = [
    {
        title: 'Range',
        dataIndex: 'range',
        key: 'range',
    },
    {
        title: 'Tasks',
        dataIndex: 'tasks',
        align: 'center',
        sorter: (a, b) => a.tasks - b.tasks,
        key: 'tasks',
    },
    {
        title: 'Successful',
        dataIndex: 'successful',
        align: 'center',
        sorter: (a, b) => a.successful - b.successful,
        key: 'successful',
    },
    {
        title: 'Failed',
        dataIndex: 'failed',
        align: 'center',
        sorter: (a, b) => a.failed - b.failed,
        key: 'failed',
    },
    {
        title: 'Profit',
        dataIndex: 'profit',
        align: 'right',
        sorter: (a, b) => a.profit - b.profit,
        key: 'profit',
        render: (profit) => +profit.toFixed(6) + " ETH"
    },
    {
        title: 'Avg. Profit',
        dataIndex: 'avg_profit',
        align: 'right',
        sorter: (a, b) => a.avg_profit - b.avg_profit,
        key: 'avg_profit',
        render: (avg_profit) => +avg_profit.toFixed(6) + " ETH"
    }
];

class TaskVolumes extends React.Component {
    constructor(props) {
        super(props);

        let fromDefault = new Date();
        fromDefault.setDate(fromDefault.getDate() - 7);

        this.state = {
            loading: false,
            error: false,
            days: 7,
            fromDate: fromDefault,
            tasks: [],
            volumes: []
        }

        this.handleSelect = this.handleSelect.bind(this)
    }

    componentDidMount() {
        this.loadOperations();
    }

    async loadVolumes(operations) {
        let volumes = ranges.map((range) => {
            return {
                tasks: 0,
                profit: 0,
                success: 0,
                error: 0
            }
        });
        operations
            .filter(task => (
                ['success', 'error'].includes(task.status)
                && !task.isFailedMemPoolTask
            ))
            .forEach(task => {
                for (const i in ranges) {
                    if (task.volume >= ranges[i][0] && task.volume < ranges[i][1]) {
                        const volume = volumes[i];
                        volume.tasks++;
                        volume.profit += task.realProfit || 0;

                        if (task.status === 'success') {
                            volume.success++;
                        } else {
                            volume.error++;
                        }

                        break;
                    }
                }
            });

        this.setState({ volumes });
    }

    async loadOperations() {
        if (this.state.loading === true) {
            return false;
        }

        this.setState({ loading: true, error: false });
        try {
            const response = await axios('/api/tasks?from=' + this.state.fromDate.getTime(), {
                headers: {
                    "x-access-token": this.props.user['accessToken']
                }
            });

            if(response.status !== 200){
                throw new Error('Failed to get tasks');
            }

            this.setState({ tasks: response.data });
            await this.loadVolumes(response.data);
        } catch (e) {
            console.log(e);
            this.setState({ error: e.message });
        }

        this.setState({ loading: false });
    }

    handleSelect(value) {
        let from = new Date();
        from.setDate(from.getDate() - value);

        this.setState({
            days: value,
            fromDate: from
        }, () => {
            this.loadOperations();
        });
    }

    render() {
        let { error, loading, volumes, days } = this.state;
        let data = volumes.map((volume, index) => {
            return {
                key: String(index),
                range: ranges[index][2] || (ranges[index][0] + '-' + ranges[index][1]),
                tasks: volume.tasks,
                successful: volume.success,
                failed: volume.error,
                profit: volume.profit,
                avg_profit: volume.tasks > 0 ? (volume.profit / volume.tasks) : 0,
            }
        })

        let csvData = getCsvData(data, ['key'])

        return (
            <div>
                <PageHeader
                    className={'main-page-content'}
                    title={<Title>Task volumes</Title>}
                    extra={[
                        <CSVLink
                            key={0}
                            filename={`task_volumes_${days}d_${moment().format("DD.MM.YYYY_HH꞉mm꞉ss")}.csv`}
                            className={"csv-download"}
                            data={csvData}
                            disabled={!!loading}
                            target="_blank"
                        >
                            csv <CloudDownloadOutlined />
                        </CSVLink>,
                        <Select key={1} defaultValue={String(days)} onChange={this.handleSelect}>
                            <Select.Option key={'1-1'} value="1">1 day</Select.Option>
                            <Select.Option key={'1-2'} value="3">3 days</Select.Option>
                            <Select.Option key={'1-3'} value="7">7 days</Select.Option>
                            <Select.Option key={'1-4'} value="30">30 days</Select.Option>
                        </Select>
                    ]}
                >
                    <div className="site-card-border-less-wrapper">
                        <Table
                            loading={loading}
                            size={"small"}
                            columns={columns}
                            dataSource={data}
                            tableLayout={"fixed"}
                            pagination={false}
                        />
                    </div>
                </PageHeader>
            </div>
        )
    }
}

export default TaskVolumes;