import React from 'react';
import ReactDOM from 'react-dom';
import { Router as BrowserRouter, Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import { createBrowserHistory } from 'history';
import "./App.less";

import Nav from './components/Nav';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Exchanges from './components/Exchanges';
import Tasks from './components/Tasks';
import Tokens from './components/Tokens';
import TaskVolumes from './components/Reports/TaskVolumes';
import TokensExtraReport from './components/Reports/TokensExtraReport';
import OneinchReport from './components/Reports/OneinchReport';
import Config from './components/Config';

const { Header, Content, Footer } = Layout;
const history = createBrowserHistory()

// Main Entry point function
class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			user: null,
			projectId: null,
			isAuthorized: false,
			exchanges: [],
			config: [],
			currency: "ETH",
			callbackFunction: (childData) => {
				this.setState({currency: childData})
			},
			changeClientIdFunction: (childData) => {
				this.setState({projectId: childData})
			}
		}

        this.updateData = this.updateData.bind(this);
	}

	async isAuthorized() {
		try {
			let user = sessionStorage.getItem('user');
			user = await JSON.parse(user);
			if (user && user['accessToken']) {
				let check = await fetch("/api/auth/user", {
					headers: {
						"x-access-token": user['accessToken']
					}
				})
				check = await check.json();
				if (check && check['username'] === user['username']) {
					await this.setState({ user: user, isAuthorized: true , projectId: 1});
					return true;
				}
			}
		} catch (e) {
			console.log(e);
		}

		return false;
	}

	componentDidMount() {
		this.isAuthorized().then((result) => {
			this.setState({ isAuthorized: result })
			if (!result) {
				history.push('/login');
			} else {
				this.updateData();
			}
			this.setState({ loading: false });
		});
	}

	async logout(e) {
		e.preventDefault();
		await sessionStorage.removeItem('user');
		window.location.href = "/";
	}

	async getExchanges() {
		let { user } = this.state;
        try {
            let response = await fetch('/api/console/exchanges', {
                headers: {
                    "x-access-token": user['accessToken']
                }
            });
            let exchanges = await response.json();

            if (response.status !== 200) {
                this.setState({ error: exchanges.message });
            } else {
                this.setState({ exchanges });
            }
        } catch (e) {
            console.log(e);
        }
    }

	async getConfig() {
		let { user } = this.state;
        try {
            let response = await fetch('/api/console/config', {
                headers: {
                    "x-access-token": user['accessToken']
                }
            });
            let config = await response.json();

            if (response.status !== 200) {
                this.setState({ error: config.message });
            } else {
                this.setState({ config });
            }
        } catch (e) {
            console.log(e);
        }
    }

	async updateData() {
		await this.getExchanges();
		await this.getConfig();
		return true;
	}

	render() {
		let { isAuthorized, loading, user, badgeValue, exchanges, config, currency, projectId } = this.state;
		return (
			<BrowserRouter history={history}>
				<Layout className="layout">
					<Header>
						<div className={"logo"}>CEX/DEX Arbitrage Platform Team console</div>
						{
							!loading && isAuthorized &&
							<Nav history={history} logout={this.logout} badgeValue={badgeValue} projectId={projectId} clientChange={this.state.changeClientIdFunction} />
						}
					</Header>
					<Content className={"app-content"}>
						<br />
						<div className="site-layout-content">
							{
								!loading && isAuthorized &&
								<Switch>
									<Redirect exact from="/login" to="/" />
									<Route path='/tokens'>
										<Tokens
											history={history}
											exchanges={exchanges}
											user={user}
											parentUpdate={this.updateData}
										/>
									</Route>
									<Route path='/exchanges'>
										<Exchanges
											history={history}
											user={user}
											parentUpdate={this.updateData}
											exchanges={exchanges}
										/>
									</Route>
									<Route path='/reports/task-volumes'>
										<TaskVolumes
											history={history}
											user={user}
										/>
									</Route>
									<Route path='/reports/tokens-extra-report'>
										<TokensExtraReport
											history={history}
											user={user}
										/>
									</Route>
									<Route path='/reports/1inch-report'>
										<OneinchReport
											history={history}
											user={user}
										/>
									</Route>
									<Route path='/config'>
										<Config
											history={history}
											user={user}
											parentUpdate={this.updateData}
											config={config}
										/>
									</Route>
									<Redirect exact from="/tasks" to={"/tasks/" + currency} />
									<Route exact path='/tasks/:currency'>
										<Tasks history={history} user={user} exchanges={exchanges} currency={currency} projectId={projectId} callback={this.state.callbackFunction} />
									</Route>
									<Route exact path='/:currency'>
										<Dashboard history={history} user={user} exchanges={exchanges} currency={currency} projectId={projectId} callback={this.state.callbackFunction} />
									</Route>
									<Redirect from="/" to={"/" + currency} />
								</Switch>
							}
							{
								!loading && !isAuthorized &&
								<Login history={history} />
							}
						</div>
					</Content>
					<Footer style={{ textAlign: 'center' }}>REMME ©{(new Date()).getFullYear()} Created by humans</Footer>
				</Layout>
			</BrowserRouter>
		);
	}
}

const AppWithRouter = withRouter(App);

// Rendering the entire react application
ReactDOM.render(<App />, document.getElementById('root'));
