export function parseUrl(search) {
    let result = {};
    try{
        let url = (search.split("?"))[1];
        if(url){
            let paramSting = url.split("&");
            for(let i in paramSting){
                let param = paramSting[i].split("=");
                if(param.length == 2){
                    result[param[0]] = param[1]
                }
            }
        }
    }catch(e){}

    return result;
}

export function getBlockExplorerLink(networkId) {
    switch (networkId) {
        case "eth":
            return "https://etherscan.io";
        case "bsc":
            return "https://bscscan.com";
        case "aur":
            return "https://explorer.aurora.dev";
        case "pol":
            return "https://polygonscan.com";
        case "arb":
            return "https://arbiscan.io";
    }
}

export function numberWithCommas(x) {
    return x.toString().replace(".",",");
}