import React from 'react';
import { Typography, Table } from "antd";
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import "./Operations.less";
import { getBlockExplorerLink } from "../../../../../../utils/utils";

const { Title } = Typography;

class Operations extends React.Component {
    constructor(props) {
        super(props);
    }

    getTableData(operations, networkId) {
        const data = [];
        if (operations && operations.length > 0) {
            let rows = 0;
            for (let i in operations) {
                let operation = operations[i];
                for (let j in operation.executedMarketOperations) {
                    let marketOperation = operation.executedMarketOperations[j];
                    data.push({
                        key: ++rows,
                        operationId: operation.id,
                        exchange: operation.exchangeId,
                        symbol: marketOperation.poolId
                            ? <a href={`${getBlockExplorerLink(operation.exchangeId)}/address/` + marketOperation.poolId} target="_blank">{marketOperation.poolId.substr(0, 8)}...{marketOperation.poolId.substr(-8)}</a>
                            : marketOperation.symbol,
                        side: marketOperation.side,
                        from: marketOperation.tradingAmountFrom,
                        to: marketOperation.tradingAmountTo,
                        to_min: marketOperation.minTradingAmountTo,
                        price: marketOperation.price,
                        traiding_fee_percent: marketOperation.tradingFeePercent,
                        borrow_amount: operation.borrowAmount,
                        withdrawal_fee: operation.withdrawalFee,
                        network_fee: operation.networkFee,
                        status: operation.isSuccessful ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <CloseCircleTwoTone twoToneColor="#eb2f96" />
                    })
                }
            }
        }

        return data;
    }

    detectRowSpan(value, row, data) {
        const obj = {
            children: value,
            props: {},
        };

        let find = data.filter((r) => r.operationId === row.operationId);
        if (find.length > 1) {
            if (row.key === find[0].key) {
                obj.props.rowSpan = find.length;
            } else {
                obj.props.rowSpan = 0
            }
        }

        return obj;
    }

    render() {
        let { operations, networkId } = this.props;
        const data = this.getTableData(operations, networkId);

        const columns = [
            {
                title: '',
                dataIndex: 'status',
                width: '25px',
                render: (value, row) => this.detectRowSpan(value, row, data)
            },
            {
                title: 'Exchange',
                dataIndex: 'exchange',
                render: (value, row) => this.detectRowSpan(value, row, data)
            },
            {
                title: 'Symbol',
                dataIndex: 'symbol',
            },
            {
                title: 'Side',
                dataIndex: 'side',
            },
            {
                title: 'From',
                dataIndex: 'from',
            },
            {
                title: 'To',
                dataIndex: 'to',
            },
            {
                title: 'To (min)',
                dataIndex: 'to_min',
            },
            {
                title: 'Price',
                dataIndex: 'price',
            },
            {
                title: 'Borrow amount',
                dataIndex: 'borrow_amount',
                render: (value, row) => this.detectRowSpan(value, row, data)
            },
            {
                title: 'Traiding fee (%)',
                dataIndex: 'traiding_fee_percent',
                render: (value, row) => this.detectRowSpan(value, row, data)
            },
            {
                title: 'Network Fee',
                dataIndex: 'network_fee',
                render: (value, row) => this.detectRowSpan(value, row, data)
            },
            {
                title: 'Withdrawal Fee',
                dataIndex: 'withdrawal_fee',
                render: (value, row) => this.detectRowSpan(value, row, data)
            }
        ]

        return (
            <div className={"operations"}>
                <div className={"task-card-section-title"}>
                    <Title level={5}>Operations</Title>
                </div>
                <Table size="small" pagination={false} columns={columns} dataSource={data} />
            </div>
        )
    }
}

export default Operations;