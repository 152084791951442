import React from 'react';
import { Tabs } from "antd";
import "./MainStatistics.less";

import CalculatedData from "./components/CalculatedData";

const { TabPane } = Tabs;

class MainStatistics extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={"main-statistics"}>
                <Tabs defaultActiveKey="1" type="card" size={"large"} style={{ marginBottom: 0 }}>
                    <TabPane tab="24 hours" key="1">
                        <CalculatedData days={1} user={this.props.user} currency={this.props.currency} projectId={this.props.projectId} />
                    </TabPane>
                    <TabPane tab="30 days" key="2">
                        <CalculatedData days={30} user={this.props.user} currency={this.props.currency} projectId={this.props.projectId} />
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

export default MainStatistics;
