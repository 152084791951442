import React from 'react';
import { Table, Row, Col } from "antd";
import moment from 'moment';
import axios from 'axios';
import "./CalculatedData.less";

class CalculatedData extends React.Component {
    mount;
    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
            tokens: {},
            rebalances: [],
            dateTo: moment().subtract(props.days, 'days'),
            loading: false,
            error: false,
        }
    }

    componentDidMount() {
        this.mount = true;
        this.loadStatistics();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currency !== this.props.currency || prevProps.projectId !== this.props.projectId) {
            this.loadStatistics()
        }
    }

    componentWillUnmount() {
        this.mount = false;
    }

    async loadStatistics() {
        if (this.state.loading === true) {
            return false;
        }

        this.setState({ loading: true });

        const [tasks, rebalances] = await Promise.all([
            this.getTasks(),
            this.getRebalances()
        ])

        const tokens = this.getTokenStatisticsPerDays(tasks);

        if (this.mount) {
            this.setState({tasks, rebalances, tokens, loading: false});
        }
    }

    async getTasks() {
        try {
            let response = await axios(`/api/tasks?projectId=${this.props.projectId}&currency=${this.props.currency}&from=` + this.state.dateTo.valueOf(), {
                headers: {
                    "x-access-token": this.props.user['accessToken']
                }
            });

            if (response.status !== 200) {
                throw new Error('Failed to get tasks');
            }
            return response.data
                .filter(task => (
                    ['success', 'error'].includes(task.status)
                    && !task.isFailedMemPoolTask
                ));
        } catch (e) {
            console.log(e);
        }

        return [];
    }

    async getRebalances() {
        try {
            let response = await axios(`/api/rebalances?currency=${this.props.currency}&days=` + this.props.days, {
                headers: {
                    "x-access-token": this.props.user['accessToken']
                }
            });
            if (response.status !== 200) {
                throw new Error('Failed to get rebalances');
            }

            return response.data;
        } catch (e) {
            console.log(e);
        }

        return [];
    }

    getTaskStatisticsPerDays(tasks) {
        try {
            const statistics = {
                total: 0,
                error: 0,
                ethTransactionFailedMining: 0,
                profitable: 0,
                success: 0,
                profit: 0,
                processedManually: 0,
                avgGasPrice: 0,
                maxGasPrice: 0,
                minGasPrice: 0,
                rebalanceFee: 0,
            };

            const txGas = [];
            tasks.forEach(task => {
                statistics.total++;
                if (task.status === 'success') {
                    statistics.success++;
                } else {
                    statistics.error++;
                }

                if (task.errorType === 'ethTransactionFailedMining') {
                    statistics.ethTransactionFailedMining++;
                }

                if (task.hasManualProcessing) {
                    statistics.processedManually++;
                }

                statistics.profit += task.realProfit;
                if (task.realProfit > 0) {
                    statistics.profitable++;
                }

                if (task?.ethTransactions?.length) {
                    task.ethTransactions.forEach(tx => {
                        if (tx.GasPrice) {
                            txGas.push(tx.GasPrice);
                        }
                    })
                }
            });
            
            if (txGas.length > 0) {
                txGas.sort(function (a, b) { return a - b });

                statistics.minGasPrice = txGas[0];
                statistics.maxGasPrice = txGas[txGas.length - 1];
                statistics.avgGasPrice = txGas[(txGas.length / 2).toFixed()];
            }

            statistics.rebalanceFee = this.state.rebalances.reduce((sum, tx) => { sum += tx.fee; return sum; }, 0);
            statistics.profit -= statistics.rebalanceFee;

            return statistics

        } catch (e) {
            console.log(e);
        }

        return {};
    }

    getTokenStatisticsPerDays(tasks) {
        const tokens = {};

        tasks
            .forEach(task => {
                if (!tokens[task.tokenSymbol]) {
                    tokens[task.tokenSymbol] = {
                        profit: 0,
                        tasks: 0,
                        success: 0,
                        error: 0,
                        volume: 0
                    }
                }
                const token = tokens[task.tokenSymbol];

                token.tasks++;
                token.profit += task.realProfit;
                if (task.status === 'success') {
                    token.success++;
                }
                if (task.status === 'error') {
                    token.error++;
                }
                token.volume += Number(task.volume);
            });

        return tokens;
    }

    render() {
        let { tasks, tokens, loading } = this.state;
        const { currency } = this.props;
        const statistics = this.getTaskStatisticsPerDays(tasks);

        let profit = statistics.profit ? +statistics.profit.toFixed(4) : 0;
        let tasksTotal = statistics.total || 0;
        let tasksSuccessful = statistics.success || 0;
        let tasksSuccessfulPercent = (statistics.success * 100 / tasksTotal) || 0;
        let tasksProfitable = statistics.profitable || 0;
        let tasksProfitablePercent = (statistics.profitable * 100 / tasksTotal) || 0;
        let tasksFailed = statistics.error || 0;
        let tasksFailedPercent = (statistics.error * 100 / tasksTotal) || 0;
        let tasksFailedDuringMining = statistics.ethTransactionFailedMining || 0;
        let tasksFailedDuringMiningPercent = (statistics.ethTransactionFailedMining * 100 / tasksTotal) || 0;
        let tasksManually = statistics.processedManually || 0;
        let totalVolume = 0;

        const tokensArray = [];

        Object.keys(tokens).forEach(symbol => {
            totalVolume += tokens[symbol].volume;
            tokensArray.push({
                key: symbol,
                symbol: symbol?.length > 10 ? symbol.slice(0,10) + '...' : symbol,
                profit: +tokens[symbol].profit.toFixed(2),
                volume: +tokens[symbol].volume.toFixed(2),
                tasks: tokens[symbol].tasks
            });
        });

        tokensArray.sort((a, b) => a.profit > b.profit ? 1 : -1);
        const topLosersSource = [].concat(tokensArray.filter(val => val.profit < 0)?.filter((value, index) => index < 10));

        tokensArray.sort((a, b) => a.profit < b.profit ? 1 : -1);
        const topPerformersSource = [].concat(tokensArray.filter(val => val.profit > 0)?.filter((value, index) => index < 10));

        const coinSource = `/public/imgs/${currency.toLowerCase()}.svg`
        const precision = currency === "ETH" ? 4 : 2;

        const calculationDataColumns = [
            {
                title: 'Property',
                dataIndex: 'property',
                key: 'property',
                width: "140px"
            },
            {
                title: 'Icon',
                dataIndex: 'icon',
                key: 'icon',
                width: "20px"
            },
            {
                title: 'Volume',
                dataIndex: 'volume',
                key: 'volume',
            },
            {
                title: 'Percent',
                dataIndex: 'percent',
                key: 'percent',
                render: (p) => <div className={"percent"}>{p}</div>
            }
        ];

        const topTokensColumns = [
            {
                title: 'Symbol',
                dataIndex: 'symbol',
                key: 'symbol',
                width: "100px"
            },
            {
                title: <>Volume <img style={{ opacity: "0.6" }} className={"coin-icon"} src={coinSource} alt={currency} /></>,
                dataIndex: 'volume',
                key: 'volume'
            },
            {
                title: 'Tasks',
                dataIndex: 'tasks',
                key: 'tasks'
            },
            {
                title: <>Profit <img style={{ opacity: "0.6" }} className={"coin-icon"} src={coinSource} alt={currency} /></>,
                dataIndex: 'profit',
                key: 'profit'
            },
        ];

        const calculationDataSource = [
            {
                key: '1',
                property: <b style={{ fontSize: "18px" }}>Total profit</b>,
                icon: <img className={"coin-icon"} src={coinSource} alt={currency} />,
                volume: <b style={{ fontSize: "18px" }}>{profit.toFixed(precision)}</b>,
            },
            {
                key: '2',
                property: <b>Volume</b>,
                icon: <img className={"coin-icon"} src={coinSource} alt={currency} />,
                volume: <b>{+totalVolume.toFixed(2)}</b>,
            },
            {
                key: 'rebalance',
                property: <><b>Rebalance fee</b></>,
                icon: <img className={"coin-icon"} src={coinSource} alt={currency} />,
                volume: <b>{+(statistics.rebalanceFee || 0).toFixed(4)}</b>,
            },
            {
                key: '3',
                property: <b>Tasks</b>,
                volume: <b>{tasksTotal}</b>,
            },
            {
                key: '4',
                property: '- successful',
                volume: <div className={"success-color"}>{tasksSuccessful}</div>,
                percent: <>{tasksSuccessfulPercent.toFixed()}%</>
            },
            {
                key: '5',
                property: '- profitable',
                volume: <div className={"profit-color"}>{tasksProfitable}</div>,
                percent: <>{tasksProfitablePercent.toFixed()}%</>
            },
            {
                key: '6',
                property: '- failed',
                volume: <div className={"fail-color"}>{tasksFailed}</div>,
                percent: <>{tasksFailedPercent.toFixed()}%</>
            },
            {
                key: '11',
                property: '- - during mining',
                volume: <div className={"fail-color"}>{tasksFailedDuringMining}</div>,
                percent: <>{tasksFailedDuringMiningPercent.toFixed()}%</>
            },
            {
                key: '7',
                property: '- manually',
                volume: <div>{tasksManually}</div>,
            },
            {
                key: 'gas-med',
                property: <><b>Gas price </b><i>(med)</i></>,
                volume: <b>{(statistics.avgGasPrice || 0).toFixed(2)}</b>,
            },
            {
                key: 'gas-max',
                property: '- max',
                volume: <div>{+(statistics.maxGasPrice || 0).toFixed(2)}</div>,
            },
            {
                key: 'gas-min',
                property: '- min',
                volume: <div>{+(statistics.minGasPrice || 0).toFixed(2)}</div>,
            },
        ]

        return (
            <div className={"calculation-data"}>
                <Row gutter={16}>
                    <Col span={24} md={{ span: 8 }}>
                        <Table loading={loading} className={"calculation-data-table"} size={"small"} dataSource={calculationDataSource} columns={calculationDataColumns} showHeader={false} bordered={false} pagination={false} />
                    </Col>
                    <Col span={24} md={{ span: 8 }}>
                        <h3>Top performers</h3>
                        <Table loading={loading} className={"top-performers-table"} size={"small"} dataSource={topPerformersSource} columns={topTokensColumns} bordered={false} pagination={false} />
                    </Col>
                    <Col span={24} md={{ span: 8 }}>
                        <h3>Top losers</h3>
                        <Table loading={loading} className={"top-losers-table"} size={"small"} dataSource={topLosersSource} columns={topTokensColumns} bordered={false} pagination={false} />
                    </Col>
                </Row>
            </div>
        )
    }
}

export default CalculatedData;
