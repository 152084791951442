import React from 'react';
import {Card, Skeleton, Tag, Tooltip, PageHeader} from "antd";
import "./BalancesCard.less";
import axios from 'axios';

class BalancesCard extends React.Component {
    mount;

    constructor(props) {
        super(props);
        this.state = {
            balances: {},
            inProgressCount: 0,
            inProgressAmount: 0,
            loading: false,
            error: false
        }

        this.reload = this.reload.bind(this)
    }

    componentDidMount() {
        this.mount = true;
        this.loadBalances();
    }

    componentWillUnmount() {
        this.mount = false;
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.currency !== this.props.currency) {
            await this.loadBalances();
        }
    }

    reload(e) {
        e.preventDefault();
        this.loadBalances();
    }

    async loadBalances() {
        let {balances} = this.state;
        const {exchanges} = this.props;

        if (this.state.loading === true) {
            return;
        }
        this.setState({loading: true});

        await this.updateTasksInProgress();

        await Promise.all(
            [
                this.updateTasksInProgress()
            ].concat(
                exchanges.map(async exchange => {
                    const currencyId = this.props.currency;
                    const exchangeId = exchange.exchangeId;

                    if(this.shouldIgnore(exchangeId, currencyId))
                        return;

                    balances[exchange.exchangeId] = {
                        exchangeName: exchange.exchangeName,
                        balance: 0,
                        balanceReceiptTimeMs: 0,
                        buy: !!exchange.isBuyingEnabled,
                        sell: !!exchange.isSellingEnabled,
                        hasError: false
                    }

                    let time_start = Date.now();
                    try {
                        let response = await axios(`/api/balance/${exchange.exchangeId}?currency=${this.props.currency}`, {
                            headers: {
                                "x-access-token": this.props.user['accessToken']
                            }
                        });

                        if (response.status !== 200 || !response.data.hasOwnProperty('volume')) {
                            throw new Error('Failed to get balance from ' + exchange.exchangeId);
                        }

                        balances[exchange.exchangeId].balance = response.data.volume;
                    } catch (e) {
                        console.log(e);
                        balances[exchange.exchangeId].hasError = true;
                    }
                    balances[exchange.exchangeId].balanceReceiptTimeMs = Date.now() - time_start;
                })
            )
        )

        if (this.mount) {
            this.setState({balances, loading: false});
        }
    }

    async updateTasksInProgress() {
        try {
            const response = await axios(`/api/tasks?projectId=${this.props.projectId}&currency=${this.props.currency}&limit=50`, {
                headers: {
                    "x-access-token": this.props.user['accessToken']
                }
            });

            const tasks = response.data.filter(task => task.status === 'inProgress');
            const inProgressCount = tasks.length;
            const inProgressAmount = tasks.reduce((sum, task) => {
                sum += task.actualAmountFrom;
                return sum
            }, 0);

            if (this.mount) {
                this.setState({inProgressCount, inProgressAmount})
            }
        } catch (e) {
            console.log(e);
            if (this.mount) {
                this.setState({error: e.message});
            }
        }
    }

    getTag(balanceItem) {
        let activity = [];
        if (balanceItem.buy) {
            activity.push("buy")
        }
        if (balanceItem.sell) {
            activity.push("sell")
        }

        if (activity.length === 0) {
            return <Tag color="gray">disabled</Tag>
        } else if (activity.length === 1) {
            return <Tag color="yellow">{activity[0]}</Tag>
        } else {
            return <Tag color="green">{activity.join(' | ')}</Tag>
        }
    }

    sortExchanges(exchanges) {
        return exchanges.sort((a, b) => (b.exchangeName.startsWith('Uniswap') - a.exchangeName.startsWith('Uniswap')));
    }

    render() {
        let {balances, inProgressCount, inProgressAmount, loading} = this.state;
        const {exchanges, currency} = this.props;

        let cardBody = [];
        let total = 0;
        const precision = currency === "ETH" ? 4 : 2;
        const currencyNormalized = currency === "AURORA" ? "AUR" : currency;
        for (let exchangeId in balances) {
            if(this.shouldIgnore(exchangeId, currency))
                continue;

            let responseTime = (balances[exchangeId].balanceReceiptTimeMs / 1000).toFixed(2);
            cardBody.push(
                <tr key={'balance-' + exchangeId}>
                    <td className={"balance-exchange-name"}>{balances[exchangeId].exchangeName}
                        <small className={"response-time-s"}>
                            <Tooltip placement="bottom" title={"Response time: " + responseTime + "s"}>
                                {responseTime}s
                            </Tooltip>
                        </small>
                    </td>
                    <td>{this.getTag(balances[exchangeId])}</td>
                    <td>{!balances[exchangeId].hasError ? +balances[exchangeId].balance.toFixed(precision) + ` ${currencyNormalized}` :
                        <b>Error!</b>}</td>
                </tr>
            )
            total += !balances[exchangeId].hasError ? balances[exchangeId].balance : 0;
        }
        total += inProgressAmount;
        cardBody.push(<tr key={'balance-in-progress'} className={'progress'}>
            <td><i>In progress</i></td>
            <td>{inProgressCount} task(s)</td>
            <td><i>{+inProgressAmount.toFixed(precision)} {currencyNormalized}</i></td>
        </tr>)
        cardBody.push(<tr key={'balance-total'} className={'total'}>
            <td>Total</td>
            <td></td>
            <td>{+total.toFixed(precision)} {currencyNormalized}</td>
        </tr>);

        return (
            <div className={"dashboard-card balances-card"}>
                <PageHeader
                    className="site-page-header"
                    backIcon={false}
                    title="Exchanges"
                >
                    <Card
                        style={{width: '100%'}}
                    >
                        <Skeleton
                            loading={loading}
                            paragraph={{rows: this.sortExchanges(exchanges).length + 2}}
                            title={false}
                            active
                        >
                            <a className={'reload'} href="#" onClick={this.reload}>Reload</a>
                            <table>
                                <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>Activity</td>
                                    <td>Balance</td>
                                </tr>
                                </thead>
                                <tbody>
                                {cardBody}
                                </tbody>
                            </table>
                        </Skeleton>
                    </Card>
                </PageHeader>
            </div>
        )
    }

    shouldIgnore(exchangeId, currencyId) {
        if (currencyId === "ETH" && exchangeId.includes("bsc"))
            return true;

        if (currencyId === "BNB" && (exchangeId.includes("eth") || exchangeId.includes("aur")))
            return true;

        if (currencyId === "AURORA" && !exchangeId.includes("eth") && !exchangeId.includes("aur") && !["mexc", "huobipro", "kucoin", "gateio", "coinbase"].includes(exchangeId))
            return true;

        if (exchangeId === "hitbtc2") {
            return true;
        }

        return false;
    }
}

export default BalancesCard;
