import React from 'react';
import "./TaskCard.less";
import { Spin } from "antd";

import Info from './components/Info';
import ManualProcessing from './components/ManualProcessing';
import Operations from './components/Operations';
import EthTrxs from './components/EthTrxs';
import Candles from "./components/Candles";

class TaskCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            task: {},
            loading: false,
            error: false
        }
        this.cardRef = React.createRef()
    }

    componentDidMount() {
        this.getCardInfo();
    }

    async getCardInfo() {
        if (this.state.loading === true || !this.props.hasOwnProperty('id')) {
            return false;
        }

        await this.setState({ loading: true, error: false });
        try {
            let task = await fetch('/api/tasks/' + this.props.id, {
                headers: {
                    "x-access-token": this.props.user['accessToken']
                }
            });
            task = await task.json();
            if (task.message) {
                await this.setState({ error: task.message });
            } else {
                await this.setState({ task });
            }
        } catch (e) {
            console.log(e);
            await this.setState({ error: e.message });
        }

        this.setState({ loading: false });
    }

    render() {
        let { task } = this.state;
        if (!task || !task.taskId) {
            return <div style={{ textAlign: 'center' }}><Spin spinning={true}></Spin></div>
        }
        return (
            <div id={'task-card-' + task.taskId} ref={this.cardRef} className={"task-card"}>
                <div className={"task-card-section"}>
                    <Info task={task} user={this.props.user} cardRef={this.cardRef} updateCardInfo={this.getCardInfo} currency={this.props.currency} />
                </div>
                {
                    task.manualProcessing && task.manualProcessing.length > 0 &&
                    <div className={"task-card-section"}>
                        <ManualProcessing manualProcessing={task.manualProcessing} />
                    </div>
                }
                <div className={"task-card-section"}>
                    <Operations operations={task.executedExchangeOperations || []} networkId={task.networkId} />
                </div>
                <div className={"task-card-section"}>
                    <EthTrxs transactions={task.ethTransactions || []} analytics={task.analytics || null} networkId={task.networkId} />
                </div>
                <div className={"task-card-section"}>
                    <Candles task={task} />
                </div>
            </div>
        )
    }
}

export default TaskCard;
