import React from 'react';
import { Redirect } from "react-router-dom";
import { Alert, Form, Input, Button, Spin } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import './Login.less'

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false
        }
    }

    async onFinish(values) {
        this.setState({ loading: true, error: false });

        try {
            let signin = await fetch(`/api/auth/signin`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values)
            });

            signin = await signin.json();
            if (signin && signin['accessToken']) {
                await sessionStorage.setItem('user', JSON.stringify(signin));
                window.location.href = "/ETH";
            }
            if (signin['message']) {
                this.setState({ error: signin['message'] });
            }
        } catch (e) {
            this.setState({ error: "Internal Server Error!" });
        }
        this.setState({ loading: false });
    };

    render() {
        let { loading, error } = this.state;

        return (
            <div className={"login-form-wrapper"}>
                <Form
                    name="login"
                    className="login-form"
                    onFinish={(value) => { this.onFinish(value) }}
                >
                    <Spin spinning={loading}>
                        {
                            !!error &&
                            <Alert message={error} type="error" banner/>
                        }
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Username!',
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Password!',
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit" className="login-form-button">
                                Log in
                            </Button>
                        </Form.Item>
                    </Spin>
                </Form>
            </div>
        );
    }
};

export default Login;