import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Badge, Menu} from 'antd';
import {DownOutlined} from '@ant-design/icons';
import "./Nav.less";

const { SubMenu } = Menu;

const pages = [
    {
        title: "Dashboard",
        route: "/",
        subMenu: []
    },
    {
        title: "Tasks",
        route: "/tasks",
        subMenu: []
    },
    {
        title: "Tokens",
        route: "/tokens",
        subMenu: []
    },
    {
        title: "Exchanges",
        route: "/exchanges",
        subMenu: []
    },
    {
        title: "Config",
        route: "/config",
        subMenu: []
    },
    {
        title: "Reports",
        route: "/reports",
        subMenu: [
            {
                title: "1inch",
                route: "/1inch-report",
            },
            {
                title: "Task volumes",
                route: "/task-volumes",
            },
            {
                title: "Tokens - Extra Report",
                route: "/tokens-extra-report",
            },
        ]
    },
];

class Nav extends React.Component {
    constructor(props) {
        super(props);
    }

    changeClientId = (projectId) => {
        this.props.clientChange(projectId);
    }

    render() {
        const { history, logout, badgeValue, projectId } = this.props;
        let find = pages.find(page => page.route.split('/')[1] === history.location.pathname.split('/')[1]) || pages[0];

        return (
            <div className={"navbar"}>
                <Menu theme="dark" mode="horizontal" selectedKeys={[String(find ? (pages.indexOf(find) + 1) : null)]}>
                    {
                        pages.map((page, index) => {
                            let badgeCount;
                            switch (page.title) {
                                case 'Tokens':
                                    badgeCount = badgeValue && badgeValue['Tokens'] ? badgeValue['Tokens'] : 0;
                                    break;
                                default:
                                    badgeCount = 0;
                            }
                            if (page.subMenu.length > 0) {
                                return (
                                    <SubMenu
                                        key={Number(index) + 1}
                                        title={<span>{page.title} <DownOutlined style={{fontSize:"10px"}} /></span>}
                                    >
                                        {
                                            page.subMenu.map((subMenuPage, i) => {
                                                return (
                                                    <Menu.Item key={Number(index) + 1 + "-"+ i}>
                                                        <Link to={page.route + subMenuPage.route}>
                                                            {subMenuPage.title}
                                                        </Link>
                                                    </Menu.Item>
                                                )
                                            })
                                        }
                                    </SubMenu>
                                )
                            } else {
                                return (
                                    <Menu.Item key={Number(index) + 1} >
                                        <Link to={page.route}>
                                            <Badge count={badgeCount} overflowCount={99}>
                                                {page.title}
                                            </Badge>
                                        </Link>
                                    </Menu.Item>
                                )
                            }

                        })
                    }
                    <SubMenu
                        key={"clientIdMenu"}
                        title={<span>ClientId {projectId}<DownOutlined style={{fontSize:"10px"}} /></span>}
                    >
                        {
                            [0,1,2].map((projectId) => {
                                return (
                                    <Menu.Item key={"TenantId " + projectId}>
                                        <a onClick={_ => this.changeClientId(projectId)}>{projectId}</a>
                                    </Menu.Item>
                                )
                            })
                        }
                    </SubMenu>
                    <Menu.Item key={pages.length + 1} style={{ float: "right" }}><a onClick={logout}>Log Out</a></Menu.Item>
                </Menu>
                <a href="/">Log Out</a>
            </div>
        )
    }
}

export default withRouter(Nav);
