import React from 'react';
import moment from 'moment';
import { CSVLink } from "react-csv";
import { PageHeader, Typography, Table, Select } from "antd"
import { CloudDownloadOutlined } from '@ant-design/icons';
import "./TokensExtraReport.less";
import { getCsvData } from '../../../utils/csvReport';
import axios from 'axios';

const { Title } = Typography;

const columns = [
    {
        title: 'Token',
        dataIndex: 'token',
        key: 'token',
    },
    {
        title: 'Tasks',
        dataIndex: 'tasks',
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.tasks - b.tasks,
        key: 'tasks',
    },
    {
        title: 'Successful',
        dataIndex: 'successful',
        sorter: (a, b) => a.successful - b.successful,
        key: 'successful',
    },
    {
        title: 'Failed',
        dataIndex: 'failed',
        sorter: (a, b) => a.failed - b.failed,
        key: 'failed',
    },
    {
        title: 'Profit',
        dataIndex: 'profit',
        align: 'right',
        sorter: (a, b) => a.profit - b.profit,
        render: value => <>{+value.toFixed(6)} ETH</>,
        key: 'profit',
    },
    {
        title: 'Volume',
        dataIndex: 'volume',
        align: 'right',
        sorter: (a, b) => a.volume - b.volume,
        render: value => <>{+value.toFixed(6)} ETH</>,
        key: 'volume'
    }
];

class TokensExtraReport extends React.Component {
    constructor(props) {
        super(props);

        let fromDefault = new Date();
        fromDefault.setDate(fromDefault.getDate() - 7);

        this.state = {
            loading: false,
            error: false,
            days: 7,
            fromDate: fromDefault,
            tokens: []
        }

        this.handleSelect = this.handleSelect.bind(this)
    }

    componentDidMount() {
        this.loadOperations();
    }

    async loadOperations() {
        if (this.state.loading === true) {
            return false;
        }

        this.setState({ loading: true, error: false });
        try {
            const response = await axios('/api/tasks?from=' + this.state.fromDate.getTime(), {
                headers: {
                    "x-access-token": this.props.user['accessToken']
                }
            });

            if(response.status !== 200){
                throw new Error('Failed to get tasks');
            }

            let tokens = {};
            response.data
                .filter(task => (
                    ['success', 'error'].includes(task.status)
                    && !task.isFailedMemPoolTask
                ))
                .forEach(task => {
                    const symbol = task.tokenSymbol.toUpperCase();
                    if (!tokens[symbol]) {
                        tokens[symbol] = {
                            profit: 0,
                            tasks: 0,
                            success: 0,
                            error: 0,
                            volume: 0
                        }
                    }

                    const token = tokens[symbol];
                    token.tasks++;

                    token.volume += task.volume;
                    token.profit += task.realProfit;

                    if (task.status === 'success') {
                        token.success++;
                    } else {
                        token.error++;
                    }
                });
            this.setState({ tokens });
        } catch (e) {
            console.log(e);
            this.setState({ error: e.message });
        }

        this.setState({ loading: false });
    }

    handleSelect(value) {
        let from = new Date();
        from.setDate(from.getDate() - value);

        this.setState({
            days: value,
            fromDate: from
        }, () => {
            this.loadOperations();
        });
    }

    render() {
        let { error, loading, days, tokens } = this.state;
        let data = [];
        for (let symbol in tokens) {
            data.push({
                key: symbol.toLowerCase(),
                token: symbol,
                tasks: tokens[symbol].tasks,
                successful: tokens[symbol].success,
                failed: tokens[symbol].error,
                profit: +tokens[symbol].profit,
                volume: +tokens[symbol].volume,
            })
        }

        let csvData = getCsvData(data, ['key']);

        return (
            <div>
                <PageHeader
                    className={'main-page-content'}
                    title={<Title>Tokens - Extra Report</Title>}
                    extra={[
                        <CSVLink
                            key={0}
                            filename={`tokens_extra_report_${days}d_${moment().format("DD.MM.YYYY_HH꞉mm꞉ss")}.csv`}
                            className={"csv-download"}
                            data={csvData}
                            disabled={!!loading}
                            target="_blank"
                        >
                            csv <CloudDownloadOutlined />
                        </CSVLink>,

                        <Select key={1} defaultValue={String(days)} onChange={this.handleSelect}>
                            <Select.Option key={'1-1'} value="1">1 day</Select.Option>
                            <Select.Option key={'1-2'} value="3">3 days</Select.Option>
                            <Select.Option key={'1-3'} value="7">7 days</Select.Option>
                            <Select.Option key={'1-4'} value="30">30 days</Select.Option>
                            <Select.Option key={'1-5'} value="90">90 days</Select.Option>
                            <Select.Option key={'1-6'} value="180">180 days</Select.Option>
                        </Select>
                    ]}
                >
                    <div className="site-card-border-less-wrapper">
                        <Table loading={loading} size={"small"} columns={columns} dataSource={data} pagination={{ defaultPageSize: 100, showSizeChanger: true, pageSizeOptions: ['10', '25', '50', '100'] }} />
                    </div>
                </PageHeader>
            </div>
        )
    }
}

export default TokensExtraReport;