import React from 'react';
import {
    Alert,
    Badge,
    Button,
    Drawer,
    Input,
    List,
    message,
    PageHeader,
    Pagination,
    Popconfirm,
    Space,
    Switch,
    Table,
    Typography
} from "antd";
import {SearchOutlined} from '@ant-design/icons';
import "./Tokens.less";

const { Title } = Typography;

class Tokens extends React.Component {
    mount;
    tableData;
    initialData;
    columns;
    duplicates = [];
    exchanges;
    constructor(props) {
        super(props);
        const params = this.formatParams();
        this.state = {
            tokens: [],
            forUpdate: [],
            loading: false,
            error: false,
            drawerVisible: false,
            filters: params,
        }

        this.onTextAreaChange = this.onTextAreaChange.bind(this)
        this.onSwitchChange = this.onSwitchChange.bind(this)
        this.onDrawerShow = this.onDrawerShow.bind(this)
        this.onDrawerClose = this.onDrawerClose.bind(this)
        this.onClearUpdates = this.onClearUpdates.bind(this)
        this.onUpdate = this.onUpdate.bind(this)
        this.deleteOneUpdate = this.deleteOneUpdate.bind(this)
        this.sendUpdateEvent = this.sendUpdateEvent.bind(this)
        this.formatParams = this.formatParams.bind(this)
    }

    formatParams() {
        const params = Object.fromEntries( new URLSearchParams(this.props.history.location.search));
        params.page = params.page || 1;
        params.limit = params.limit || 20;
        if (params.exchangeId) {
            params.exchangeId = params.exchangeId?.split(',');
        }
        if (params.isBuyEnabled) {
            params.isBuyEnabled = params.isBuyEnabled.split(',');
        }
        if (params.isSellEnabled) {
            params.isSellEnabled = params.isSellEnabled.split(',');
        }
        return params;
    }
    componentDidMount() {
        this.mount = true;
        this.loadTokens(this.state.filters).then(_ => {
            this.setInitialTableData();
        });
    }

    componentWillUnmount() {
        this.mount = false;
    }

    async loadTokens(filters) {
        if (this.state.loading === true) {
            return false;
        }

        const params = new URLSearchParams({...this.state.filters, ...filters});
        this.props.history.push({
            search: '?' + params
        });
        let filtersQuery = '';
        if (filters) {
            for (let filterKey of Object.keys(filters)) {
                if (filters[filterKey] !== null &&  filters[filterKey] !== undefined && filterKey !== 'page' && filterKey !== 'limit') {
                    filtersQuery += '&' + filterKey + '=' + filters[filterKey];
                }
            }
        }
        this.duplicates = [];
        this.setState({ loading: true, error: false, forUpdate: [] });

        try {
            let tokensResponse = await fetch(`/api/arbitrageTokens/tokens?page=${this.state.filters.page}&limit=${this.state.filters.limit}${filtersQuery}`, {
                headers: {
                    "x-access-token": this.props.user['accessToken']
                }
            });
            tokensResponse = await tokensResponse.json();

            if (this.mount) {
                if (tokensResponse.message) {
                    this.setState({error: tokensResponse.message});
                } else {
                    const {pagination} = tokensResponse;
                    this.setState({
                        tokens: tokensResponse.tokens,
                        pages: pagination.pages,
                        totalCount: pagination.totalCount,
                    });
                }
            }
        } catch (e) {
            console.log(e);
            if (this.mount) {
                this.setState({error: e.message});
            }
        }

        if (this.mount) {
            this.setState({loading: false});
        }
    }

    async loadToken(tokenId) {
        try {
            let tokenResponse = await fetch(`/api/arbitrageTokens/tokens/${tokenId}`, {
                headers: {
                    "x-access-token": this.props.user['accessToken']
                }
            });
            tokenResponse = await tokenResponse.json();

           return tokenResponse.token;
        } catch (e) {
            console.log(e);
            if (this.mount) {
                this.setState({error: e.message});
            }
        }
    }

    setInitialTableData() {
        let { tokens } = this.state;

        const data = [];

        for (let i in tokens) {
            let token = tokens[i];

            data.push({
                id: token.id,
                key: token.exchangeId + "-" + token.id,
                exchangeId: token.exchangeId,
                networkId: token.networkId,
                contractAddress: token.contractAddress,
                ticker: token.ticker,
                fullName: token.fullName,
                isBuyEnabled: token.isBuyEnabled,
                isSellEnabled: token.isSellEnabled,
                note: token.note,
                modified: false,
            })
        }

        for (let i in data) {
            let item = data[i];
            let res = data.filter(record =>
                item.exchangeId === record.exchangeId
                && item.ticker === record.ticker
            );

            if (res.length > 1) {
                this.duplicates.push(item);
            }
        }
        this.tableData = data;
        this.initialData = data.slice(0);

        const networksWithoutTokensList = [
            'uniswap',
            'uniswap2',
            'uniswap3',
            'hitbtc2'
        ]
        this.exchanges = this.props.exchanges
            .filter(ex => !networksWithoutTokensList.find(name => name === ex.exchangeId))
            .map(ex => {
                if (ex.exchangeId === 'ethContract')  {
                    ex.exchangeId = 'eth';
                    ex.exchangeName = 'Eth'
                }
                return ex;
            } );

        this.columns = [
            {
                title: 'Exchange',
                dataIndex: 'exchangeId',
                filters: this.exchanges.map(ex => { return { text: ex.exchangeName, value: ex.exchangeId} }),
                filteredValue: this.state.filters?.exchangeId || null,
            },
            {
                title: 'Network',
                dataIndex: 'networkId',
                ...this.getColumnSearchProps('networkId'),
            },
            {
                title: 'Contract address',
                dataIndex: 'contractAddress',
                ...this.getColumnSearchProps('contractAddress'),
                render: (value, record) => {
                    return <Input.TextArea rows={1} style={{ width: '100%' }} bordered={false} value={value} onChange={(e) => this.onTextAreaChange(e, 'contractAddress', record)} />
                },
                width: '400px'
            },
            {
                title: 'Ticker',
                dataIndex: 'ticker',
                ...this.getColumnSearchProps('ticker'),
            },
            {
                title: 'Full Name',
                dataIndex: 'fullName',
                ...this.getColumnSearchProps('fullName'),
            },
            {
                title: 'Buy',
                dataIndex: 'isBuyEnabled',
                filters: [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }],
                filteredValue: this.state.filters?.isBuyEnabled || null,
                render: (value, record) => {
                    return <Switch size="small" checked={!!value} onChange={(checked) => this.onSwitchChange(checked, 'isBuyEnabled', record)} />
                }
            },
            {
                title: 'Sell',
                dataIndex: 'isSellEnabled',
                filters: [{ text: 'Yes', value: 1 }, { text: 'No', value: 0 }],
                filteredValue: this.state.filters?.isSellEnabled || null,
                render: (value, record) => {
                    return <Switch size="small" checked={!!value} onChange={(checked) => this.onSwitchChange(checked, 'isSellEnabled', record)} />
                }
            },
            {
                title: 'Note',
                dataIndex: 'note',
                width: '300px',
                ...this.getColumnSearchProps('note'),
                render: (value, record) => {
                    return <Input.TextArea rows={1} style={{ width: '100%' }} value={value} onChange={(e) => this.onTextAreaChange(e, 'note', record)} />
                }
            },
        ];
        if (this.mount) {
            this.setState({loading: false});
        }
    }

    getColumnSearchProps(dataIndex) {
        return {
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            this.searchInput = node;
                        }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                            Reset
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                confirm({ closeDropdown: false });
                                this.setState({
                                    searchText: selectedKeys[0],
                                    searchedColumn: dataIndex,
                                });
                            }}
                        >
                            Filter
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            },
            filteredValue: this.state.filters[dataIndex] || null,
        }
    };

    handleSearch(selectedKeys, confirm, dataIndex) {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset(clearFilters) {
        clearFilters();
        this.setState({ searchText: '' });
    };

    async onTextAreaChange(e, field, record) {
        let { forUpdate } = this.state;
        let value = e.target.value;
        if (value === "") {
            value = null;
        }

        const dbToken = await this.loadToken(record.id);

        let updateIndex = forUpdate.findIndex((updateObj) => updateObj.id === record.id);

        if (updateIndex < 0) {
            forUpdate.push({
                id: record.id,
                data: {
                    [field]: value,
                }
            });
            record.modified = true;
        } else {
            const updateData = forUpdate[updateIndex].data;

            if (dbToken[field] === value) {
                delete updateData[field];
                record.modified = Object.keys(updateData).length > 0; // Check if any other fields are still modified
            } else {
                updateData[field] = value;
                record.modified = true;
            }
        }

        record[field] = value;

        this.setState({
            forUpdate: this.clearEmptyUpdates(forUpdate)
        });
    }

    onSwitchChange(value, field, record) {
        console.log(record);
        const { forUpdate } = this.state;
        const updateIndex = forUpdate.findIndex(update => update.id === record.id);

        if (updateIndex === -1) {
            forUpdate.push({
                id: record.id,
                data: {
                    [field]: value ? 1 : 0
                }
            });
            record.modified = true;
        } else {
            forUpdate[updateIndex].data[field] = value ? 1 : 0;
            record.modified = true;
        }

        this.setState({
            forUpdate: this.clearEmptyUpdates(forUpdate)
        });

        record[field] = !record[field];
    }

    clearEmptyUpdates(updates) {
        return updates.filter(update => Object.keys(update.data).length > 0);
    }

    deleteOneUpdate(id) {
        let { forUpdate } = this.state;
        let data = forUpdate.filter((row) => !(row.id === id))
        this.setState({
            forUpdate: data
        })
    }

    onDrawerShow() {
        this.setState({
            drawerVisible: true
        })
    }

    onDrawerClose() {
        this.setState({
            drawerVisible: false
        })
    }

    onClearUpdates() {
        this.setState({
            forUpdate: []
        })
        this.loadTokens();
        this.onDrawerClose();
    }

    onPageChange(page, limit) {
        this.setState({
            filters: {
                ...this.state.filters,
                page,
                limit,
            },
        }, () => {
            this.loadTokens(this.state.filters).then(_ => {
                this.setInitialTableData();
            });
        });
    }

    onFiltersChange(changedFilters) {
        let filters = {};
        if (changedFilters) {
            for (let filterKey of Object.keys(changedFilters)) {
                if (changedFilters[filterKey] !== null && changedFilters[filterKey] !== undefined) {
                    filters[filterKey]= changedFilters[filterKey];
                }
            }
        }

        this.setState({
            filters: {
                page: this.state.filters.page,
                limit: this.state.filters.limit,
                ...filters,
            }
        }, () => {
            this.loadTokens(filters).then(_ => {
                this.setInitialTableData();
            });
        })

    }

    async onUpdate() {
        let { forUpdate } = this.state;
        if (this.state.loading === true || !forUpdate || forUpdate.length === 0) {
            return false;
        }

        this.setState({ loading: true, error: false });

        try {
            let update = await fetch('/api/arbitrageTokens/tokens', {
                method: 'put',
                headers: {
                    'Content-Type': 'application/json',
                    "x-access-token": this.props.user['accessToken']
                },
                body: JSON.stringify({
                    updates: forUpdate
                })
            });
            if (update.status !== 200) {
                this.setState({ error: update.message });
            } else {
                message.success('Updated');
                this.onDrawerClose();
                this.onClearUpdates();
                await this.setState({ loading: false });
                await this.loadTokens();
            }
        } catch (e) {
            console.log(e);
            this.setState({ error: e.message });
        }
        this.setState({ loading: false });
    }

    async sendUpdateEvent() {
        this.setState({ loading: true, error: false });

        try {
            let update = await fetch('/api/arbitrageTokens/tokens/updateEvent', {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    "x-access-token": this.props.user['accessToken']
                }
            });
            let updateJson = await update.json();
            if (update.status !== 200) {
                message.error(updateJson.message);
            } else {
                message.success('Signal sent successfully');
            }
        } catch (e) {
            console.log(e);
            this.setState({ error: e.message });
        }

        this.setState({ loading: false });
    }

    render() {
        let { error, loading, forUpdate, drawerVisible } = this.state;

        return (
            <div>
                <PageHeader
                    className={'main-page-content'}
                    title={<Title>Tokens</Title>}
                    extra={[
                        <Popconfirm
                            key={1}
                            title="Are you sure to send update event?"
                            onConfirm={this.sendUpdateEvent}
                            okText="Yes"
                            cancelText="No"
                            okButtonProps={{ loading: loading }}
                        >
                            <Button type="primary" disabled={loading}>
                                Send update event
                            </Button>
                        </Popconfirm>,
                        <Badge key={2} count={forUpdate.length} overflowCount={99}>
                            <Button type="primary" disabled={!forUpdate.length} onClick={this.onDrawerShow}>
                                Update
                            </Button>
                        </Badge>,
                    ]}
                >
                    <div className="site-card-border-less-wrapper">
                        {
                            !!error &&
                            <Alert message={error} type="error" banner />
                        }
                        {
                            this.duplicates.length > 0 &&
                            <Alert message={"Duplicates have been found"} type="warning" banner />
                        }

                        <Table
                            columns={this.columns}
                            dataSource={this.tableData}
                            size="small"
                            pagination={false}
                            loading={loading}
                            onChange={(pagination, filters) => this.onFiltersChange(filters)}
                        />
                        <div className={'pagination'}>
                            <Pagination current={this.state.filters.page}
                                        total={this.state.totalCount}
                                        pageSize={this.state.filters.limit}
                                        onChange={(page, pageSize) => this.onPageChange(page, pageSize)}/>
                        </div>
                    </div>
                    <Drawer
                        title="Update"
                        width={720}
                        onClose={this.onDrawerClose}
                        visible={drawerVisible}
                        bodyStyle={{ paddingBottom: 80 }}
                    >
                        <List
                            className="update-list"
                            itemLayout="horizontal"
                            dataSource={forUpdate}
                            renderItem={item => (
                                <List.Item
                                    actions={[<a key="update-list-delete" onClick={() => this.deleteOneUpdate(item.id)}>delete</a>]}
                                >
                                    <List.Item.Meta
                                        title={<b>Token to update: {item.id}</b>}
                                        description={JSON.stringify(item.data)}
                                    />
                                    <hr />
                                </List.Item>
                            )}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                right: 0,
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 16px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={this.onClearUpdates} type="danger" loading={loading} style={{ marginRight: 8, float: 'left' }}>
                                Clear
                            </Button>
                            <Button onClick={this.onDrawerClose} loading={loading} style={{ marginRight: 8 }}>
                                Cancel
                            </Button>
                            <Button onClick={this.onUpdate} loading={loading} type="primary">
                                Submit
                            </Button>
                        </div>
                    </Drawer>
                </PageHeader>
            </div>
        )
    }
}

export default Tokens;
