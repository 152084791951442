import React from 'react';
import moment from 'moment';
import axios from 'axios';
import {CSVLink} from "react-csv";
import {Alert, Button, DatePicker, message, PageHeader, Space, Spin, Typography} from "antd";
import {CloudDownloadOutlined} from '@ant-design/icons';
import { withRouter } from "react-router-dom";
import {getCsvData} from '../../utils/csvReport';
import "./Tasks.less";

import TaskList from '../TaskList';
import {getDateFormat, taskHandler} from '../TaskList/taskListHelpers';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const step = 1000;
let loadingID = null;

class Tasks extends React.Component {
    mount;
    constructor(props) {
        super(props);

        this.state = {
            operations: [],
            csvData: [],
            loading: false,
            csvLoading: false,
            error: false,
            datePickerValue: this.getDefaultDateRange()
        }

        this.loadOperations = this.loadOperations.bind(this);
        this.onChangeDatePicker = this.onChangeDatePicker.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    getDefaultDateRange() {
        return [
            moment().subtract(30, 'days'),
            moment()
        ];
    }

    componentDidMount() {
        this.mount = true;
        this.loadOperations();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.match.params.currency !== this.props.match.params.currency ||
            prevState.datePickerValue !== this.state.datePickerValue ||
            prevProps.projectId !== this.props.projectId
        ) {
            this.loadOperations()
        }
    }

    componentWillUnmount() {
        this.mount = false;
    }

    changeToCurrency = (currencyId) => {
        this.props.callback(currencyId);
        const urlParams = new URLSearchParams(this.props.history.location.search);
        const from = urlParams.get('from') ? moment(urlParams.get('from'), 'x') : null;
        const to = urlParams.get('from') ? moment(urlParams.get('to'), 'x') : null;
        this.props.history.push({
            pathname: `/tasks/${currencyId}`,
            search: this.props.history.location?.search,
        });
        this.setState({ datePickerValue: from && to ? [from,to] : this.getDefaultDateRange()})
    }


    async loadOperations() {
        const { datePickerValue } = this.state;
        let timeFrom = datePickerValue[0].toDate().getTime();
        let timeTo = datePickerValue[1].toDate().getTime();

        loadingID = timeFrom + "-" + timeTo;

        this.setState({
            csvData: [],
            operations: [],
            loading: true,
            error: false
        });

        for (let i = 0; i >= 0; i += step) {
            try {
                let response = await fetch(`/api/tasks?projectId=${this.props.projectId}&from=${timeFrom}&to=${timeTo}&limit=${step}&offset=${i}&currency=${this.props.match.params.currency}`, {
                    headers: {
                        "x-access-token": this.props.user['accessToken']
                    }
                });

                if (loadingID !== timeFrom + "-" + timeTo) {
                    return false;
                }

                let operations = await response.json();
                if (response.status !== 200) {
                    if (this.mount) {
                        this.setState({ error: operations.message });
                    }
                    break;
                } else {
                    let newOperations = taskHandler([].concat(...this.state.operations), operations);
                    if (this.mount) {
                        this.setState({operations: newOperations});
                    }
                    if (operations.length < step) {
                        break;
                    }
                }
            } catch (e) {
                if (this.mount) {
                    this.setState({error: e.message});
                }
            }
        }

        if (this.mount) {
            this.setState({loading: false});
        }
        loadingID = null;
    }

    refresh() {
        this.loadOperations();
    }

    async onChangeDatePicker(value) {
        this.props.history.push({
            pathname: `/tasks/${this.props.match.params.currency}`,
            search: `?projectId=${this.props.projectId}&from=${value[0].toDate().getTime()}&to=${value[1].toDate().getTime()}&currency=${this.props.match.params.currency}`
        });

        this.setState({ datePickerValue: value });
    }

    getCsvData = async () => {
        const { datePickerValue } = this.state;
        let timeFrom = datePickerValue[0].toDate().getTime();
        let timeTo = datePickerValue[1].toDate().getTime();

        this.setState({ loadingCsv: true })
        try {
            let response = await axios.get(`/api/tasks/report?projectId=${this.props.projectId}&currency=${this.props.match.params.currency}&from=${timeFrom}&to=${timeTo}`);
            if (response.status === 200) {
                this.setState({
                    csvData: getCsvData(response.data),
                    loadingCsv: false
                });
                return true;
            }
        } catch (e) {
            message.error(e.message, 8);
        }

        message.error("Failed to create CSV file", 8);
        this.setState({
            csvData: [],
            loadingCsv: false
        });
    }

    csvDataState = () => {
        return this.state.csvData;
    }

    render() {
        let { error, loading, loadingCsv, csvData, operations, datePickerValue } = this.state;
        const currency = this.props.match.params.currency
        const coinSource = `/public/imgs/${currency.toLowerCase()}.svg`
        return (
            <div className={"task-list"}>
                <PageHeader
                    className={'main-page-content'}
                    title={<Title>Tasks {loading && operations.length > 1 && <Spin />}</Title>}
                    avatar={{src: coinSource}}
                    subTitle={
                        <Space>
                            <Button type={'primary'} onClick={_ => this.changeToCurrency("ETH")}>ETH</Button>
                            <Button type={'primary'} onClick={_ => this.changeToCurrency("USDT")}>USDT</Button>
                            <Button type={'primary'} onClick={_ => this.changeToCurrency("USDC")}>USDC</Button>
                            <Button type={'primary'} onClick={_ => this.changeToCurrency("BNB")}>BNB</Button>
                            <Button type={'primary'} onClick={_ => this.changeToCurrency("AURORA")}>AURORA</Button>
                            <Button type={'primary'} className={'refresh-icon'} disabled={loading}  onClick={_ => this.refresh()}>
                                <img  src="/public/imgs/refresh.svg"/>
                            </Button>
                        </Space>
                    }
                    extra={[
                        <span key={"1"}>
                            {
                                csvData.length === 0 || loadingCsv
                                    ? <Button
                                        key={"create-csv"}
                                        loading={loadingCsv}
                                        disabled={loadingCsv}
                                        onClick={this.getCsvData}
                                    >
                                        Create CSV report
                                    </Button>
                                    : <CSVLink
                                        key={"download-csv"}
                                        filename={`tasks_${this.props.match.params.currency}_${datePickerValue[0].format("DD.MM.YYYY_HH꞉mm꞉ss")}_${datePickerValue[1].format("DD.MM.YYYY_HH꞉mm꞉ss")}.csv`}
                                        data={csvData}
                                    >
                                        <Button key={"download-button"} type={'primary'}>Download CSV file <CloudDownloadOutlined /></Button>
                                    </CSVLink>
                            }
                        </span>,
                        <RangePicker
                            key={"range-picker"}
                            showTime={{ format: 'HH:mm' }}
                            format={getDateFormat()}
                            defaultValue={datePickerValue}
                            disabled={loadingCsv}
                            onCalendarChange={this.onChangeDatePicker}
                            value={datePickerValue}
                        />
                    ]}
                >
                    <div className="site-card-border-less-wrapper">
                        {
                            !!error &&
                            <Alert message={error} type="error" banner />
                        }
                        <TaskList
                            loading={loading && operations.length === 0}
                            operations={operations}
                            user={this.props.user}
                            reload={this.loadOperations}
                            showSearchInput={true}
                            currency={currency}
                            projectId={this.props.projectId}
                        />
                    </div>
                </PageHeader>
            </div>
        )
    }
}

export default withRouter(Tasks);
