import React from 'react';
import {Button, Col, PageHeader, Row, Space, Typography} from "antd";
import BalancesCard from './components/BalancesCard';
import ShortTaskList from './components/ShortTaskList';
import TasksDailyChart from './components/TasksDailyChart';
import MainStatistics from './components/MainStatistics';
import {withRouter} from "react-router-dom";
import './Dashboard.less'

const { Title } = Typography;

class Dashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            exchanges: null,
        }
    }

    changeToCurrency = (currencyId) => {
        this.props.callback(currencyId);
        this.props.history.push(`/${currencyId}`)
    }

    render() {
        const { exchanges, projectId } = this.props;
        const currency = this.props.match.params.currency
        const coinSource = `/public/imgs/${currency.toLowerCase()}.svg`

        return (
            <div className={'dashboard'}>
                <PageHeader
                    className={'main-page-content'}
                    title={<Title>Dashboard</Title>}
                    avatar={{src: coinSource}}
                    subTitle={
                        <Space>
                            <Button type={'primary'}  onClick={_ => this.changeToCurrency("ETH")}>ETH</Button>
                            <Button type={'primary'}  onClick={_ => this.changeToCurrency("USDT")}>USDT</Button>
                            <Button type={'primary'}  onClick={_ => this.changeToCurrency("USDC")}>USDC</Button>
                            <Button type={'primary'}  onClick={_ => this.changeToCurrency("BNB")}>BNB</Button>
                            <Button type={'primary'}  onClick={_ => this.changeToCurrency("AURORA")}>AURORA</Button>
                        </Space>
                    }
                >
                    <div className="site-card-border-less-wrapper">
                        <Row gutter={16}>
                            <Col span={24} order={2} xl={{ span: 17, order: 1 }}>
                                <MainStatistics user={this.props.user} currency={currency} projectId={projectId} />
                            </Col>
                            <Col span={24} order={1} xl={{ span: 7, order: 2 }}>
                                {
                                    <BalancesCard user={this.props.user} exchanges={exchanges || []} currency={currency} projectId={projectId} />
                                }
                            </Col>
                        </Row>
                        <TasksDailyChart user={this.props.user} currency={currency} projectId={projectId} />
                        <ShortTaskList user={this.props.user} exchanges={exchanges} currency={currency} projectId={projectId} />
                    </div>
                </PageHeader>
            </div>
        )
    }
}

export default withRouter(Dashboard);
