import React from 'react';
import {Alert, Button, PageHeader, Space} from "antd";
import {Link} from 'react-router-dom';
import "./ShortTaskList.less";

import TaskList from '../../../TaskList';

class ShortTaskList extends React.Component {
    loadTasks;
    mount;
    constructor(props) {
        super(props);
        this.state = {
            operations: [],
            loading: false,
            error: false,
        }

        this.loadOperations = this.loadOperations.bind(this);
        this.refresh = this.refresh.bind(this);
    }


    componentDidMount() {
        this.mount = true;
        this.loadOperations();
        this.loadTasks = setInterval(() => {
            this.loadOperations();
        }, 10000);
    }

    componentWillUnmount() {
        this.mount = false;
        clearInterval(this.loadTasks);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currency !== this.props.currency || prevProps.projectId !== this.props.projectId) {
            this.loadOperations(this.props.currency);
        }
    }

    async loadOperations(currency) {
        if (this.state.loading === true) {
            return false;
        }

        this.setState({ loading: true, error: false, operations: currency ? [] : this.addKeyToOperations(this.state.operations)});
        try {
            let operations = await fetch(`/api/tasks?projectId=${this.props.projectId}&limit=300&currency=${this.props.currency}`, {
                headers: {
                    "x-access-token": this.props.user['accessToken']
                },
            });
            operations = await operations.json();
            if (this.mount) {
                this.setState({operations: this.addKeyToOperations(operations)});
            }
        } catch (e) {
            console.log(e);
            if (this.mount) {
                this.setState({error: e.message});
            }
        }

        if (this.mount) {
            this.setState({loading: false});
        }
    }

    addKeyToOperations(operations) {
        operations.forEach(op => {
            op.key = op.id;
        })
        return operations;
    }

    refresh() {
        this.loadOperations();
    }

    render() {
        let { error, loading, operations } = this.state;

        operations.sort((a, b) => a.status === "inProgress" ? -1 : 0);
        operations.sort((a, b) => a.status === "inProgress" ? -1 : 0);

        return (
            <div className={"short-task-list"}>
                {
                    !!error &&
                    <Alert message={error} type="error" banner />
                }
                <PageHeader
                    className="site-page-header"
                    backIcon={false}
                    title="Last tasks"
                    subTitle={
                        <Space>
                            <span>Short list</span>
                            <Button type={'primary'} className={'refresh-icon'} disabled={loading}  onClick={_ => this.refresh()}>
                                <img  src="/public/imgs/refresh.svg"/>
                            </Button>
                        </Space>
                    }
                    loading={loading}
                    extra={[
                        <Button key="tasks-button"><Link to={"/tasks/" + this.props.currency}>View more</Link></Button>,
                    ]}
                >
                    <TaskList
                        operations={operations}
                        loading={loading}
                        showFilter={false}
                        showTaskCardButton={false}
                        showEditButton={true}
                        reload={this.loadOperations}
                        user={this.props.user}
                        projectId={this.props.projectId}
                    />
                </PageHeader>
            </div>
        )
    }
}

export default ShortTaskList;
