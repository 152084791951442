import React from 'react';
import {Button, DatePicker, PageHeader, Select, Spin} from "antd";
import moment from 'moment';
import {Bar, CartesianGrid, ComposedChart, Label, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {DownOutlined, LoadingOutlined, UpOutlined} from '@ant-design/icons';
import './TasksDailyChart.less'
import CustomGraphOptionsModal from "./CustomGraphOptionsModal";


const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = "DD.MM.YYYY";


class TasksDailyChart extends React.Component {
    mount;
    isOpenedVolumes = false;
    controller = new AbortController();

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            error: false,
            datePickerValue: this.getDefaultDateRange(),
            triggerTypes: [
                "cexMarketUpdate",
                "dexReservesUpdate",
                "mempool",
                "kolibrio",
                "rpcFast",
                "mevBlocker",
                "flashbots"
            ],
            filteredByTriggerType: [],
            showUniV3: true,
            uniV3loading: false,
            showUniV2: true,
            uniV2loading: false,
            addGraph: false,
            additionalGraphs: [],
        }
        this.handleFilterByTriggerType = this.handleFilterByTriggerType.bind(this);
        this.onChangeDatePicker = this.onChangeDatePicker.bind(this);
        this.toggleVolumes = this.toggleVolumes.bind(this);
        this.getVolumesStatistics = this.getVolumesStatistics.bind(this);
        this.writeGraph = this.writeGraph.bind(this);
        this.getUniV3Graph = this.getUniV3Graph.bind(this);
        this.getUniV2Graph = this.getUniV2Graph.bind(this);
        this.initAddGraph = this.initAddGraph.bind(this);
        this.createNewGraph = this.createNewGraph.bind(this);
        this.getCustomGraphData = this.getCustomGraphData.bind(this);
        this.refreshGraphInfo = this.refreshGraphInfo.bind(this);
        this.deleteCustomGraph = this.deleteCustomGraph.bind(this);
    }

    componentDidMount() {
        this.mount = true;
        this.getTasksDailyStatistics();
        this.getVolumesStatistics();
    }

    componentWillUnmount() {
        this.controller.abort();
        this.mount = false;
        clearTimeout(this.filterTimer);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currency !== this.props.currency || prevProps.projectId !== this.props.projectId) {
            this.getTasksDailyStatistics()
            this.getVolumesStatistics();
        }
    }

    getDefaultDateRange() {
        return [
            moment().subtract(30, 'days').startOf("day"),
            moment().endOf("day")
        ];
    }

    CustomTooltip({ active, payload, label, currency }) {
        const precision = currency === "ETH" ? 4 : 2;
        const coinSource = `/public/imgs/${currency.toLowerCase()}.svg`
        if (active && payload && payload.length) {
            let tasks = payload[0].value + payload[1].value;
            return (
                <div className="custom-tooltip">
                    <p className="tooltip-label">{label}</p>
                    <div className="tooltip-body">
                        <div><b>Profit</b></div>
                        <div><span className={"icon profit-real-line-icon"}></span> real: {payload[2].value.toFixed(precision) || 0} <img className={"coin-icon"} src={coinSource} alt={currency}/></div>
                        <div><span className={"icon profit-expected-line-icon"}></span> expected: {payload[2].payload.estimatedProfit.toFixed(precision)} <img className={"coin-icon"} src={coinSource} alt={currency}/></div>
                        <div><span className={"icon"}></span> ratio: {payload[2].payload.realToEstimatedProfit}%</div>
                        <div><b>Tasks: {tasks}</b></div>
                        <div><span className={"icon tasks-success-line-icon"}></span> success: {payload[1].value} <i>({(payload[1].value * 100 / tasks).toFixed()}%)</i></div>
                        <div><span className={"icon tasks-error-line-icon"}></span> error: {payload[0].value} <i>({(payload[0].value * 100 / tasks).toFixed()}%)</i>, during mining: {payload[0].payload.errorEthTransactionFailedMining} <i>({(payload[0].payload.errorEthTransactionFailedMining * 100 / tasks).toFixed()}%)</i></div>
                        <div><b>Gas price, gwei</b></div>
                        <div><span className={"icon"}></span> max: {payload[2].payload.maxGasPrice}</div>
                        <div><span className={"icon"}></span> min: {payload[2].payload.minGasPrice}</div>
                        <div><span className={"icon"}></span> avg: {payload[2].payload.avgGasPrice}</div>
                    </div>
                </div>
            );
        }

        return null;
    };

    CustomVolumesTooltip({ active, payload, label }) {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="tooltip-label">{label}</p>
                    <div className="tooltip-body">
                        <div><b>Volumes</b></div>
                        {(payload.length > 1 && payload[0]?.value || payload[0]?.name === "uniV3dayVolumeUSD") && <div><span className={"icon profit-real-line-icon"}></span> UniV3 Volume USD: ${(+payload[0]?.value)?.toLocaleString(undefined, {maximumFractionDigits: 2}) || 0} </div>}
                        {(payload.length > 1 && payload[1]?.value || payload[0]?.name === "uniV2dayVolumeUSD") && <div><span className={"icon profit-expected-line-icon"}></span> UniV2 Volume USD: ${(+(payload.length > 1 ? payload[1] : payload[0])?.value)?.toLocaleString(undefined, {maximumFractionDigits: 2}) || 0} </div> }
                    </div>
                </div>
            );
        }

        return null;
    };

    async getTasksDailyStatistics() {
        const { loading, datePickerValue, filteredByTriggerType } = this.state;
        if (loading === true) {
            return false;
        }

        let timeFrom = datePickerValue[0].toDate().getTime();
        let timeTo = datePickerValue[1].toDate().getTime();

        if (this.mount) {
            this.setState({loading: true, error: false});
        } else {
            return ;
        }
        try {
            let statistics = await fetch(`/api/tasks/statistics/count/daily?projectId=${this.props.projectId}&currency=${this.props.currency}&from_timestamp=${(timeFrom / 1000).toFixed()}&to_timestamp=${(timeTo / 1000).toFixed()}${filteredByTriggerType.length > 0 ? "&triggerTypes=" + filteredByTriggerType.join(",") : ""}`, {
                signal: this.controller.signal,
                headers: {
                    "x-access-token": this.props.user['accessToken']
                }
            });
            statistics = await statistics.json();
            if (statistics && statistics[0]) {
                let data = [];
                for (let i in statistics[0]) {
                    let raw = statistics[0][i];
                    let dateStr = new Date();
                    dateStr.setFullYear(raw.y);
                    dateStr.setMonth(raw.m - 1);
                    dateStr.setDate(raw.d);

                    let realProfit = raw.profit ? Number(raw.profit.toFixed(4)) : 0;
                    let estimatedProfit = raw.estimatedProfit ? Number(raw.estimatedProfit.toFixed(4)) : 0;
                    data.push({
                        name: dateStr.toLocaleDateString(),
                        success: raw.success || 0,
                        error: raw.error || 0,
                        errorEthTransactionFailedMining: raw.ethTransactionFailedMining || 0,
                        profit: realProfit,
                        estimatedProfit: estimatedProfit,
                        realToEstimatedProfit: Number((realProfit * 100 / estimatedProfit).toFixed(2)),
                        maxGasPrice: raw.maxGasPrice ? Number(raw.maxGasPrice.toFixed(4)) : 0,
                        minGasPrice: raw.minGasPrice ? Number(raw.minGasPrice.toFixed(4)) : 0,
                        avgGasPrice: raw.avgGasPrice ? Number(raw.avgGasPrice.toFixed(4)) : 0,
                    })
                }
                if (this.mount) {
                    this.setState({data, loading: false});
                }
            }
        } catch (e) {
            console.log(e);
            if (this.mount) {
                this.setState({error: e.message});
            }
        }
        if (this.mount) {
            this.setState({loading: false});
        }
    }

    async getVolumesStatistics() {
        const { loading, datePickerValue } = this.state;
        if (loading === true) {
            return false;
        }

        let timeFrom = datePickerValue[0].toDate().getTime();
        let timeTo = datePickerValue[1].utc(true)?.toDate().getTime();

        if (this.mount) {
            this.setState({loadingVolumes: true, error: false});
        } else {
            return ;
        }

        let univ2data = [];
        let univ3data = [];

        this.getUniV3Graph(timeFrom, timeTo).then(data => {
            univ3data = data;
            this.writeGraph(univ3data, univ2data, timeFrom, timeTo);
        });
        this.getUniV2Graph(timeFrom, timeTo).then(data => {
            univ2data = data;
            this.writeGraph(univ3data, univ2data, timeFrom, timeTo);
        });

    }

    async getUniV3Graph(timeFrom, timeTo) {
        let univ3data = [];
        let tries = 3;
        if (this.mount) {
            this.setState({uniV3loading: true});
        }
        while(tries !== 0 && this.mount) {
            try {
                univ3data = await fetch(`/api/tasks/statistics/univ3daily?from_timestamp=${(timeFrom / 1000).toFixed()}&to_timestamp=${(timeTo / 1000).toFixed()}`,
                    {
                        signal: this.controller.signal,
                        headers: {
                            "x-access-token": this.props.user['accessToken']
                        },
                    });
                if (univ3data.status === 200) {
                    this.setState({uniV3loading: false});
                    break;
                }
            } catch (e) {
                if (e.name === "AbortError") return;
                console.log(e);
                if (--tries === 0) {
                    if (this.mount) {
                        this.setState({error: e.message, uniV3loading: false});
                    }
                }
            }
        }

        return  await univ3data?.json();
    }

    async getUniV2Graph(timeFrom, timeTo) {
        let univ2data = [];
        let tries = 3;
        if (this.mount) {
            this.setState({uniV2loading: true});
        }
        while(tries !== 0 && this.mount) {
            try {
                univ2data = await fetch(`/api/tasks/statistics/univ2daily?from_timestamp=${(timeFrom / 1000).toFixed()}&to_timestamp=${(timeTo / 1000).toFixed()}`,
                    {
                        signal: this.controller.signal,
                        headers: {
                            "x-access-token": this.props.user['accessToken']
                        },
                    });
                if (univ2data.status === 200) {
                    this.setState({uniV2loading: false});
                    break;
                }
            } catch (e) {
                if (e.name === "AbortError") return;
                console.log(e);
                if (--tries === 0) {
                    if (this.mount) {
                        this.setState({error: e.message, uniV2loading: false});
                    }
                }

            }
        }
        return await univ2data?.json();
    }

    writeGraph(univ3data, univ2data, timeFrom, timeTo) {
        if (univ3data && univ3data?.length || univ2data && univ2data?.length) {
            let data = [];
            for (let time = (timeFrom + 10800000) / 1000; time < (timeTo/1000); time+=86400) {
                const dateStr = new Date(time * 1000);
                const uniV3dayVolumeUSD = univ3data?.reduce((acc, curr) => {
                    const dayInfo = +curr?.poolDayData?.find(dayData => dayData?.date === time)?.volumeUSD || 0;
                    acc = +acc + dayInfo;
                    return acc;
                }, 0) || 0;

                const uniV2dayVolumeUSD = univ2data?.reduce((acc, curr) => {
                    const dayInfo = +curr?.date === time ? +curr?.dailyVolumeUSD : 0;
                    acc = +acc + dayInfo;
                    return acc;
                }, 0) || 0;
                data.push({uniV3dayVolumeUSD, uniV2dayVolumeUSD, date: dateStr.toLocaleDateString()});
            }

            if (this.mount) {
                this.setState({volumesData: data});
            }
        }
    }

    async onChangeDatePicker(value) {
        value[0] = value[0].startOf("day");
        value[1] = value[1].endOf("day");
        if (this.mount) {
            this.controller.abort();
            this.controller = new AbortController();
            await this.setState({ datePickerValue: value });
            await this.getTasksDailyStatistics();
            await this.refreshGraphInfo();
            await this.getVolumesStatistics();

        }
    }

    filterTimer;
    handleFilterByTriggerType(value) {
        clearTimeout(this.filterTimer);

        this.filterTimer = setTimeout(() => {
            if (this.mount) {
                this.setState({
                    filteredByTriggerType: value
                }, this.getTasksDailyStatistics);
            }
        }, 2000);
    };


    toggleVolumes() {
        this.isOpenedVolumes = !this.isOpenedVolumes;
        if (this.mount) {
            this.setState({openedVolumes: this.isOpenedVolumes});
        }
    }

    initAddGraph() {
        this.setState({
            addGraph: !this.state.addGraph
        })
    }

    createNewGraph(e) {
        if (!e.key) {
            return;
        }

        this.getCustomGraphData(e.name, e.key, e.filters).then(graphData => {
            if (!graphData) {
                return;
            }
            const graphs = [...this.state.additionalGraphs];
            graphs.push({
                id: this.state.additionalGraphs.length + 1,
                name: e.name,
                key: e.key,
                filters: e.filters,
                data: graphData.plotData?.map(plot => {
                    const date = moment(plot.date).format("DD.MM.YYYY");
                    return {date, value: plot.value}
                }) || [],
            });
            this.setState({
                additionalGraphs: graphs
            })
        });
    }

    async refreshGraphInfo() {
        const {additionalGraphs} = this.state;
        for (let graph of additionalGraphs) {
            await this.getCustomGraphData(graph.name, graph.key, graph.filters).then(graphData => {
                additionalGraphs.find(g => g.id === graph.id).data = graphData.plotData?.map(plot => {
                    const date = moment(plot.date).format("DD.MM.YYYY");
                    return {date, value: plot.value}
                }) || [];
            })

        }
        if (this.mount) {
            this.setState({
                additionalGraphs: [...additionalGraphs]
            })
        }
    }

    async getCustomGraphData(name,key,filters) {
        const { datePickerValue } = this.state;

        let timeFrom = moment(datePickerValue[0]).subtract(moment(datePickerValue[1]).utcOffset(), 'hours').toDate().getTime();
        let timeTo = moment(datePickerValue[1]).subtract(moment(datePickerValue[1]).utcOffset(), 'hours').toDate().getTime();
        let params = '';
        if (filters) {
            Object.keys(filters).forEach(filter => {
                params += '&'+filter+'='+filters[filter]?.toString();
            })
        }
        try {
            const customGraphData = await fetch(`/api/tasks/customPlot?dateFrom=${timeFrom}&dateTo=${timeTo}&plotName=${key}${params}`,
                {
                    signal: this.controller.signal,
                    headers: {
                        "x-access-token": this.props.user['accessToken']
                    },
                });

            if (customGraphData.status === 200) {
                return await customGraphData.json();
            }
        } catch (e) {
            if (e.name === "AbortError") return;
            return null;
        }

    }

    deleteCustomGraph(graph) {
        this.setState({
            additionalGraphs: this.state.additionalGraphs.filter(g => g.id !== graph.id),
        });
    }

    render() {
        const { data, loading, datePickerValue, triggerTypes, volumesData, openedVolumes, showUniV3, showUniV2, uniV3loading, uniV2loading, addGraph, additionalGraphs } = this.state;

        return (
            <div className={"tasks-daily-chart"}>
                <PageHeader
                    key={'daily-statistic-graph-header'}
                    className="site-page-header"
                    backIcon={false}
                    title="Daily task statistics"
                    loading={loading}
                    extra={[
                        <Spin
                            key={"spin"}
                            size="small"
                            spinning={loading}
                            style={{ marginRight: 10 }}
                            indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                        />,
                        <Select
                            key={"filter-by-triggertype"}
                            className={'filter-by-triggertype'}
                            mode="multiple"
                            allowClear
                            placeholder="Filter by triggerType"
                            defaultValue={[]}
                            disabled={loading}
                            onChange={this.handleFilterByTriggerType}
                            maxTagCount={'responsive'}
                        >
                            {
                                triggerTypes.map(type=> <Option key={type} >{type}</Option>)
                            }
                        </Select>,
                        <RangePicker
                            key={"range-picker"}
                            showTime={false}
                            disabled={loading}
                            format={dateFormat}
                            defaultValue={datePickerValue}
                            onCalendarChange={this.onChangeDatePicker}
                        />
                    ]}
                >
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart
                            width={500}
                            height={500}
                            data={data}
                            margin={{
                                top: 20,
                                right: 10,
                                left: 0,
                                bottom: 20,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name">
                                <Label value="Date" offset={-10} position="insideBottom" />
                            </XAxis>
                            <YAxis yAxisId="left" orientation="left">
                                <Label value="Tasks" offset={30} position="bottom" />
                            </YAxis>
                            <YAxis yAxisId="right" orientation="right">
                                <Label value="Profit" offset={30} position="bottom" />
                            </YAxis>
                            <Tooltip content={<this.CustomTooltip currency={this.props.currency} />} />
                            <Bar dataKey="error" yAxisId="left" stackId="a" fill="#ff7066" />
                            <Bar dataKey="success" yAxisId="left" stackId="a" fill="#3395ff" label={{ position: 'top', }} />
                            <Line dataKey="profit" type="monotone" yAxisId="right" fill="#000" />
                            <Line dataKey="estimatedProfit" type="monotone" yAxisId="right" fill="#ffd766" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </PageHeader>
                {
                    additionalGraphs.map(graph =>
                        <PageHeader
                            className="site-page-header"
                            backIcon={false}
                            title={graph.name}
                            key={'custom-graphs-'+graph.id}
                            extra={[
                                <Button
                                    className={'additional-graph-delete'}
                                    key="toggle"
                                    shape="circle"
                                    disabled={loading}
                                    onClick={_ => this.deleteCustomGraph(graph)}>
                                    .
                                </Button>,
                            ]}
                            // loading={loading}
                        >
                            <ResponsiveContainer width="100%" height="100%">
                                <ComposedChart
                                    width={500}
                                    height={500}
                                    data={graph.data}
                                    margin={{
                                        top: 20,
                                        right: 10,
                                        left: 0,
                                        bottom: 20,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="5 5" />
                                    <XAxis dataKey="date" xAxisId={1}>
                                        <Label value="Date" offset={-10} position="insideBottom" />
                                    </XAxis>
                                    <YAxis yAxisId="right" orientation="right" scale={'linear'}  type="number" >
                                        <Label value="Value" offset={30} position="bottom" />
                                    </YAxis>
                                    <YAxis yAxisId="left" orientation="left" scale={'linear'} type="number" >
                                        <Label value="Value" offset={30} position="bottom" />
                                    </YAxis>
                                    <Bar dataKey="value" xAxisId={1} yAxisId="right" stackId="a" fill="#ff7066" />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </PageHeader>
                    )
                }
                <Button type={'primary'} className={'refresh-icon'} disabled={loading} onClick={_ => this.initAddGraph()} >
                    + Add custom graph
                </Button>
                {
                    addGraph &&
                    <CustomGraphOptionsModal visible={!!addGraph}
                                             onClose={this.initAddGraph}
                                             callback={e => this.createNewGraph(e)}></CustomGraphOptionsModal>
                }

                <PageHeader
                    key={'volumes-graph-header'}
                    className="volume-page-header"
                    backIcon={false}
                    title="Daily USD Volumes for UniV3 and UniV2"
                    loading={loading}
                    extra={[
                            <Button
                                key="toggle"
                                shape="circle"
                                icon={openedVolumes ? <UpOutlined  /> : <DownOutlined  />}
                                disabled={loading}
                                onClick={this.toggleVolumes}
                            ></Button>,
                    ]}>
                        {openedVolumes &&
                            <div className={'volumes-container'}>
                                <div className={'volume-btns-container'}>
                                    <Button className={showUniV3 ? 'show' : ''} type={'primary'} onClick={() => this.setState({showUniV3: !showUniV3})} >Uniswap V3</Button>
                                    <Button className={showUniV2 ? 'show' : ''} type={'primary'} onClick={() => this.setState({showUniV2: !showUniV2})}>Uniswap V2</Button>
                                    {uniV2loading &&
                                        <div className="volume-loading">UniV2 Graph loading...</div>}
                                    {uniV3loading &&
                                        <div className="volume-loading">UniV3 Graph loading...</div>}
                                </div>
                                <div className={'volumes-container-chart'}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <ComposedChart
                                            width={500}
                                            height={500}
                                            data={volumesData}
                                            margin={{
                                                top: 20,
                                                right: 10,
                                                left: 0,
                                                bottom: 20,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="5 5" />
                                            <XAxis dataKey="date" xAxisId={1}>
                                                <Label value="Date" offset={-10} position="insideBottom" />
                                            </XAxis>
                                            <XAxis dataKey="date" xAxisId={2}  hide={true}>
                                            </XAxis>
                                            <YAxis yAxisId="left" orientation="left" scale={'linear'} type="number" tickFormatter={val =>  ((+val / 1000) > 1000 ) ? ('$' + (+val / 1000000).toFixed(0) + 'mil') : ('$' +(val / 1000).toFixed(1) + 'k')}>
                                                <Label value="Volume" offset={30} position="bottom" />
                                            </YAxis>
                                            <YAxis yAxisId="right" orientation="right" scale={'linear'}  type="number" tickFormatter={val =>  ((+val / 1000) > 1000 ) ? ('$' + (+val / 1000000).toFixed(0) + 'mil') : ('$' +(val / 1000).toFixed(1) + 'k')}>
                                                <Label value="Volume" offset={30} position="bottom" />
                                            </YAxis>
                                            <Tooltip content={<this.CustomVolumesTooltip />} />
                                            {
                                                showUniV3 &&
                                                    <Bar dataKey="uniV3dayVolumeUSD" xAxisId={1} yAxisId="right" stackId="a" fill="#3395ff" />
                                            }
                                            {
                                                showUniV2 &&
                                                <Bar dataKey="uniV2dayVolumeUSD" xAxisId={1} yAxisId="right" stackId="a" fill="#ff7066" />
                                            }

                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </div>

                            </div>
                        }
                </PageHeader>
            </div>
        )
    }
}

export default TasksDailyChart;
